import { useContext, useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import configFile from "config.json";
import axiosCustom from "utils/axios";
import axios from "axios";
import { Button, Input, Popconfirm, Space, Table, Tag } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAuthContext } from "contexts/auth";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
const DeleteCombos = () => {
  const { jwtToken } = useContext(getAuthContext);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const {
    data: comboMenus,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [`getComboMenusByAnyStore`],
    queryFn: () => fetchDataComboMenus(),
  });

  const fetchDataComboMenus = async () => {
    const response = await axios.post(
      `${configFile.PROD_SERVER_URL}/menu/getComboMenusByAnyStore`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );
    return response.data;
  };
  const deleteComboMenus = async (data) => {
    try {
      const response = await axiosCustom.post(
        `${configFile.PROD_SERVER_URL}/menu/deleteComboMenusByComboMenuIdAndMasterName`,
        { combo_menu_id: data?.combo_menu_id, master_name: data?.master_name }
      );
      return response.data;
    } catch (error) {
      throw error?.response;
    }
  };

  const deleteCombo = (data) => {
    mutate(data);
  };

  const { mutate } = useMutation({
    mutationFn: (data) => deleteComboMenus(data),
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      NotificationManager.error(error?.data?.errorMessage, "Алдаа");
    },
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  return (
    <StyledC>
      <h3>Багц устгах</h3>
      <Table
        // handleActionButton={handleInstance}
        columns={columns
          // ?.filter((column) => column?.dataIndex !== "action")
          ?.map((column) => ({
            ...column,
            ...(column?.key !== "action"
              ? getColumnSearchProps(column?.dataIndex)
              : {}),
          }))}
        dataSource={comboMenus?.data?.map((item, index) => ({
          ...item,
          key: index,
          delete: deleteCombo,
        }))}
        loading={isLoading}
        pagination={false}
        bordered
        //   scroll={{
        //     y: 500,
        //   }}
        expandable={{
          expandedRowRender: (record) => {
            // if(record?.master_name && record?.combo_menu_id)
            const storeArr = record?.stores?.split(",") ?? [];
            return (
              <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                {storeArr?.map((store) => (
                  <Tag color="green" key={store}>
                    {store}
                  </Tag>
                ))}
              </div>
            );
          },
        }}
      />
    </StyledC>
  );
};

export default DeleteCombos;
const StyledC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const columns = [
  {
    title: "master_name",
    dataIndex: "master_name",
    key: "master_name",
    fixed: "left",
  },
  {
    title: "combo_menu_id",
    dataIndex: "combo_menu_id",
    key: "combo_menu_id",
  },
  {
    title: "store_count",
    key: "store_count",
    dataIndex: "store_count",
  },
  //   {
  //     title: "stores",
  //     key: "stores",
  //     dataIndex: "stores",
  //     render: (_) => {
  //       const storeArr = _?.split(",") ?? [];
  //       return (
  //         <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
  //           {storeArr?.map((store) => (
  //             <Tag color="green" key={store}>
  //               {store}
  //             </Tag>
  //           ))}
  //         </div>
  //       );
  //     },
  //   },
  {
    title: "Action",
    // dataIndex: "action",
    key: "action",
    fixed: "right",
    width: 100,
    render: (_, data) => {
      return (
        <Popconfirm
          title={`${data?.master_name} | ${data?.combo_menu_id}`}
          description={`${data?.master_name} устгахдаа итгэлгэй байна уу?`}
          onConfirm={() => data?.delete(_)}
          onCancel={() => {}}
          okText="Тийм"
          cancelText="Үгүй"
        >
          <Button danger>Устгах</Button>
        </Popconfirm>
      );
    },
  },
];
