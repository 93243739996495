import { Affix, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { topMenuItems } from 'utils/menuItems';
const Menus = () => {
  const navigate = useNavigate();
  const handleMenu = (ev) => {
    navigate(ev.key);
  };
  return (
    <Menu
      theme='dark'
      // defaultSelectedKeys={['1']}
      mode='inline'
      items={topMenuItems}
      onClick={handleMenu}
    />
  );
};
export default Menus;
