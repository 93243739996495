import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { BsKeyFill } from 'react-icons/bs';
import { NotificationManager } from 'react-notifications';
import { getAuthContext } from 'contexts/auth';
import { getReferenceContext } from 'contexts/reference';
import { Button, Form } from 'antd';
import { EmailInput, PasswordInput, Input } from 'components/Form/InputTypes';
import styled from 'styled-components';

const Register = () => {
  const [form] = Form.useForm();
  const { loggedIn, setLoggedIn } = React.useContext(getAuthContext);
  const { setIsLoading } = React.useContext(getReferenceContext);
  const [formType, setFormType] = React.useState('signUp');
  const [email, setEmail] = React.useState('');

  const navigate = useNavigate();
  if (loggedIn) navigate('/');
  const handleSubmit = async () => {
    try {
      const data = await form.validateFields();
      if (data.password1 !== data.password2) {
        NotificationManager.info('Нууц үг таарахгүй байна');
        return;
      }
      try {
        const { user } = await Auth.signUp({
          username: data.email,
          password: data.password1,
          attributes: { email: data.email },
          autoSignIn: { enabled: true },
        });
        setEmail(data.email);
        //   console.log(user, 'user what?');
        NotificationManager.info('Email-ээ шалгана уу');
        setFormType('confirmSignUp');
      } catch (e) {
        NotificationManager.error(e.message);
        console.log('error signup in ', e);
      }
      // handleSubmit(data);
    } catch (error) {
      //   NotificationManager.error('Мэйл хаяг эсвэл Нууц үг буруу байна');
    }
  };
  const handleConfirmSignup = async (e) => {
    e.preventDefault();
    try {
      const { confirmationCode } = await form.validateFields();
      try {
        await Auth.confirmSignUp(email, confirmationCode);
        NotificationManager.success('Амжилттай бүртгэгдлээ');
        navigate('/');
      } catch (error) {
        if (error.__type === 'CodeMismatchException') {
          NotificationManager.error('Баталгаажуулах код буруу байна');
        }
      }
    } catch (error) {
      // setConfirmationCode('');
      // NotificationManager.error(error.message);
    }
  };
  return (
    <Form form={form}>
      <Container>
        <img src='/logo.png' />
        <h2>Бүртгүүлэх</h2>
        {formType === 'signUp' && (
          <>
            <EmailInput required={true} name='email' form={form} />
            <PasswordInput
              required={true}
              name='password1'
              placeholder='Нууц үгээ оруулна уу'
              message='Нууц үгээ оруулна уу !'
              form={form}
            />
            <PasswordInput
              required={true}
              name='password2'
              placeholder='Нууц үгээ давтаж оруулна уу'
              message='Нууц үгээ давтаж оруулна уу !'
              form={form}
            />
            <div className='btm'>
              <Button
                className={'login'}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                type='primary'
                icon={<BsKeyFill />}
                size={'medium'}
                htmlType='submit'
              >
                Бүртгүүлэх
              </Button>
              <span>эсвэл</span>

              <Button
                className={'black'}
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/login');
                }}
                type='primary'
                icon={<BsKeyFill />}
                size={'medium'}
                htmlType='submit'
                styles={{ background: 'black' }}
              >
                Нэвтрэх
              </Button>
            </div>
          </>
        )}
        {formType === 'confirmSignUp' && (
          <>
            <Input
              name='confirmationCode'
              required
              type='text'
              placeholder='Confirmation code'
              form={form}
              message={'Баталгаажуулах код'}
            />
            <div className='btm'>
              <Button
                className={'login'}
                size={'medium'}
                htmlType='submit'
                type='primary'
                onClick={handleConfirmSignup}
              >
                Бүртгэл баталгаажуулах
              </Button>
              <span>эсвэл</span>
              <Button
                size={'medium'}
                className={'black'}
                htmlType='submit'
                type='primary'
                onClick={() => navigate('/login')}
              >
                <BsKeyFill />
                Нэвтрэх
              </Button>
            </div>
          </>
        )}
      </Container>
    </Form>
  );
};

export default Register;
const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 220px;
  margin: auto;
  img {
    width: 100px;
  }
  h2 {
    text-align: center;
  }
  .btm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    > * {
      width: 100%;
    }
    > span {
      text-align: center;
      font-size: 12px;
    }
    .login {
      background: ${({ theme }) => theme.color};
    }
    .black {
      background: ${({ theme }) => theme.dark};
    }
  }
`;
