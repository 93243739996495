import { Button, Collapse, Form, Input, Modal as AntModal, theme } from 'antd';
import SelectGroups from 'components/SelectGroups';
import configFile from 'config.json';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import { CaretRightOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import axios from 'utils/axios';
import { copyToClipBoard } from 'utils/etc';

const { Panel } = Collapse;
const Modal = ({
  saveButtonText,
  cancelButtonText,
  isShowModal,
  setIsShowModal,
  instance,
  title,
  groups,
  handleConfirmSignup,
  handleDisableUser,
  handleEnableUser,

  // saveGroup,
}) => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const [newGroups, setNewGroups] = useState([]);
  const [oldGroups, setOldGroups] = useState([]);
  const [setPassword, setSetPassword] = useState(null);

  useEffect(() => {
    fetchUserGroups();
  }, [instance]);

  const fetchUserGroups = async () => {
    try {
      const {
        data: { data: checkedLists },
      } = await axios.post(
        `${configFile.PROD_SERVER_URL}/user/listGroupsForUser`,
        {
          username: instance.username,
        }
      );
      const data = splitGroups(checkedLists);
      setOldGroups([...data.groupData, ...data.storeGroupData]);
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, 'Error');
      console.log(error);
    }
  };

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  const setPasswordUser = async () => {
    const {
      data: { data: password },
    } = await axios.post(`${configFile.PROD_SERVER_URL}/user/setUserPassword`, {
      username: instance.username,
    });
    setSetPassword(password);
  };
  const handleCancel = () => {
    setIsShowModal(false);
  };
  const handleGroup = async () => {
    let addGroups = [];
    let removeGroups = [];
    oldGroups.forEach((item) => {
      const g = newGroups.filter((group) => group === item);
      if (g.length === 0) removeGroups.push(item);
    });
    newGroups.forEach((item) => {
      const g = oldGroups.filter((group) => group === item);
      if (g.length === 0) addGroups.push(item);
    });

    try {
      const addUserToGroup = axios.post(
        `${configFile.PROD_SERVER_URL}/user/addUserToGroup`,
        { username: instance.username, groups: addGroups }
      );
      const removeUserFromGroup = axios.post(
        `${configFile.PROD_SERVER_URL}/user/removeUserFromGroup`,
        { username: instance.username, groups: removeGroups }
      );
      await Promise.all([addUserToGroup, removeUserFromGroup]);
      fetchUserGroups();
      NotificationManager.success('Амжилттай хадгаллаа');
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, 'Error');
    }
  };

  return (
    <AntModal
      width={1000}
      // onOk={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {cancelButtonText}
        </Button>,
      ]}
      onCancel={handleCancel}
      open={isShowModal}
      okText={saveButtonText}
      cancelText={cancelButtonText}
      okType='primary'
    >
      <Form form={form}>
        <Container>
          <div className='head'>
            {instance.username} {title}
          </div>
          <div className='body-container'>
            <Collapse
              bordered={false}
              // defaultActiveKey={['1']}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <StyledPanel header='Хэрэглэгчийн эрх' key='1' style={panelStyle}>
                <SelectGroups
                  form={form}
                  username={instance.username}
                  groups={groups}
                  setNewGroups={setNewGroups}
                  oldGroups={oldGroups}
                />
                <div className='action'>
                  <Button
                    onClick={handleGroup}
                    disabled={oldGroups.toString() === newGroups.toString()}
                    type='primary'
                  >
                    Хадгалах
                  </Button>
                </div>
              </StyledPanel>
              <Panel header='Хэрэглэгчийн тохиргоо' key='2' style={panelStyle}>
                <div className='user-settings'>
                  <Button
                    onClick={handleConfirmSignup}
                    type='primary'
                    disabled={
                      !instance?.enabled || instance?.userStatus === 'CONFIRMED'
                    }
                  >
                    ConfirmSignup
                  </Button>
                  <Button
                    onClick={handleEnableUser}
                    type='primary'
                    disabled={instance?.enabled}
                  >
                    Enable User
                  </Button>
                  <Button
                    onClick={handleDisableUser}
                    type='primary'
                    disabled={!instance?.enabled}
                  >
                    Disable User
                  </Button>
                  <div className='flex'>
                    <Button
                      onClick={setPasswordUser}
                      type='primary'
                      // disabled={!instance?.enabled}
                    >
                      SetPasswordUser
                    </Button>
                    {setPassword && (
                      <Input
                        style={{ cursor: 'pointer' }}
                        readOnly
                        value={setPassword}
                        onClick={() => {
                          copyToClipBoard(setPassword);
                        }}
                      />
                    )}
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Container>
      </Form>
    </AntModal>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  .head {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: flex;
  }
  .body-container {
    .sectionItem {
      .sectionTitle {
        padding-bottom: 10px;
      }
    }
  }
  .user-settings {
    display: flex;
    gap: 20px;
    .flex {
      display: flex;
      gap: 10px;
    }
  }
`;
const StyledPanel = styled(Panel)`
  .action {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
`;
const SectionHead = styled.div`
  h4 {
  }
  width: 100%;
  height: 100%;
`;
const splitGroups = (data) => {
  let groupData = [];
  let storeGroupData = [];
  data.forEach((element) => {
    if (element.includes('STORE_')) {
      storeGroupData.push(element);
    } else {
      groupData.push(element);
    }
  });
  return { groupData, storeGroupData };
};

export default Modal;
