import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Sidebar from 'components/Sidebar';
import Logo from 'components/Logo';
import Menu from 'components/Menu';
import Logout from 'components/Logout';
import { getAuthContext } from 'contexts/auth';
import { Affix, Layout as AntLayout, theme } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
const { Header, Content } = AntLayout;

const Layout = ({ children }) => {
  const { user } = React.useContext(getAuthContext);
  const items = [
    {
      label: <a href='/logout'>Гарах</a>,
      key: '0',
    },
  ];
  return (
    <StyledAntLayout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sidebar>
        <div className='fixed'>
          <Logo />
          <Menu />
        </div>
      </Sidebar>
      <AntLayout className='site-layout'>
        <Header
          style={{
            padding: 0,
            background: '#fff',
            maxHeight: 40,
          }}
        >
          <div className='profileName'>
            <Dropdown
              menu={{
                items,
              }}
              trigger={['click']}
            >
              <p onClick={(e) => e.preventDefault()}>
                <Space>
                  <UserOutlined />
                  {user?.username}
                  <DownOutlined />
                </Space>
              </p>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            // margin: '24px 16px',
            padding: 20,
            minHeight: 680,
            // background: "#fff",
          }}
        >
          <div className='content'>{children}</div>
        </Content>
      </AntLayout>
    </StyledAntLayout>
  );
};

export default Layout;

const StyledAntLayout = styled(AntLayout)`
  .content {
    > * {
      padding: 20px;
      background: #fff;
    }
  }
  .profileName {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
  }
  .fixed {
    position: sticky;
    top: 0;
  }
`;
