import { Button, Table } from "antd";
// import Table from 'components/Table/StoreTable';
import configFile from "config.json";
import { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import axiosCustom from "utils/axios";
import axios from "axios";
import { tablePosCheck as columns } from "utils/tableModels";
import { getAuthContext } from "contexts/auth";

const Store = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { jwtToken } = useContext(getAuthContext);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const { data } = await axiosCustom.post(
        `${configFile.PROD_SERVER_URL}/store/list`
      );
      const mappedData = data.data.map((item, index) => {
        return {
          storeName: item.storeName,
          hrsUrl: "",
          success: "",
          key: index + 1,
        };
      });
      setData(mappedData);
      setTableData(mappedData);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
      console.log(error);
    }
  };
  const checkPos = () => {
    data.forEach((store) => {
      let newArray = [...tableData];
      const newDat = newArray.find(
        (item) => item.storeName === store.storeName
      );
      newDat.success = "pending";
      newDat.hrsUrl = "";
      // setTimeout(() => {
      setTableData([...newArray]);
      // });
      axios
        .post(
          `${configFile.PROD_SERVER_URL}/store/checkStoreEndpoint`,
          { storeName: store.storeName },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((response) => {
          const {
            data: { data: newData },
          } = response;
          let newArrayData = [...tableData];
          const updateItem = newArrayData.find(
            (item) => item.storeName === newData.storeName
          );
          updateItem.success = newData.success;
          updateItem.hrsUrl = newData.hrsUrl;
          setTimeout(() => {
            setTableData([...newArrayData]);
          });
        });
    });
  };

  return (
    <Container>
      <div className="head">
        {/* {data.length > 0 && (
            <SelectStore form={form} name='store' onChange={onChangeStore} />
          )} */}
        <Button onClick={() => checkPos()} className="check-button">
          Пос шалгах
        </Button>

        <Table
          // handleActionButton={handleInstance}
          pagination={false}
          columns={columns}
          dataSource={tableData}
          // loading={true}
          // scroll={{ y: '80vh' }}
        />
      </div>
    </Container>
  );
};
const Container = styled.div`
  background-color: #fff;
  .head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .check-button {
      margin-bottom: 20px;
    }
  }
  .modal {
    width: 600px;
  }
`;
export default Store;
