import { Form, Input, Modal as AntModal, Row } from 'antd';
import * as InputTypes from 'components/Form/InputTypes';
import ScheduleFormDay from 'components/ScheduleFormDay';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { namedWeeklyday } from 'utils/formatChange';

const Modal = ({
  width,
  saveButtonText,
  cancelButtonText,
  isShowModal,
  setIsShowModal,
  model,
  instance,
  handleSave,
  title,
}) => {
  const [datas, setDatas] = useState([]);
  useEffect(() => {}, []);
  const handleValidate = async () => {
    let data = [];
    try {
      await Promise.all(
        datas.map(async (item) => {
          let result = await item.form.validateFields();
          result.id = item.id;
          result.weekDay = item.day;
          Object.keys(model).map((field) => {
            const obj = model[field];
            if (obj.type === 'SelectTime') {
              result[field] = result[field]?.format('HH:mm');
            }
            if (field === 'endTimeShift' || field === 'redirectEndTimeShift') {
              result[field] = result[field] ? 1 : 0;
            }
          });
          data.push(result);
        })
      );
      console.log(data);
      handleSave(data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = async () => {
    setIsShowModal(false);
  };
  return (
    <AntModal
      width={width}
      onOk={handleValidate}
      onCancel={handleCancel}
      open={isShowModal}
      okText={saveButtonText}
      cancelText={cancelButtonText}
      okType='primary'
    >
      <Container>
        <div className='title'>{title}</div>
        <div className='body'>
          <div className='body_content'>
            {instance.map((day, index) => {
              return (
                <div key={index} className='days'>
                  <div className='day'>{namedWeeklyday(day.weekDay)}</div>
                  <div className='form'>
                    <ScheduleFormDay model={model} day={day} forms={setDatas} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </AntModal>
  );
};
const Container = styled.div`
  .title {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    display: flex;
  }
  .current_store {
    margin: 10px 0;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
    input {
      width: auto;
    }
    label {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .body {
    > h4 {
      margin: 20px 0;
    }
  }
  .days {
    display: flex;
    gap: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    .day {
      flex: 1;
    }
    .form {
      display: flex;
      gap: 25px;
      flex-wrap: wrap;
      flex: 15;
    }
  }
  .field {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;

    label {
      font-size: 12px;
      font-weight: 500;
    }
  }
`;
export default Modal;
