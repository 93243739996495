import { Form, Modal as AntModal } from "antd";
import * as InputTypes from "components/Form/InputTypes";
// import configFile from 'config.json';
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

const Modal = ({
  // width,
  saveButtonText,
  cancelButtonText,
  isShowModal,
  setIsShowModal,
  model,
  instance,
  type,
  handleSaveData,
  handleSearch,
}) => {
  const [form] = Form.useForm();
  const district = Form.useWatch("district", form);
  const city = Form.useWatch("city", form);
  const [title, setTitle] = useState("");
  console.log(city)
  useEffect(() => {
    if (type === "createAddress") {
      setTitle("Хаяг нэмэх");
    } else if (type === "editAddress") {
      setTitle(`${instance.bairname} хаяг засах`);
    }
  }, [type]);

  const handleSave = async () => {
    try {
      let values = await form.validateFields();
      handleSaveData(values, instance, type);
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, "Error");
    }
  };
  const handleCancel = () => {
    setIsShowModal(false);
  };
  return (
    <AntModal
      // width={width}
      onOk={handleSave}
      onCancel={handleCancel}
      open={isShowModal}
      okText={saveButtonText}
      cancelText={cancelButtonText}
      okType="primary"
    >
      <Container>
        <div className="title">
          <p>{title}</p>
        </div>
        <Form form={form} {...layout}>
          {Object.keys(model).map((field) => {
            const object = model[field];
            const InputType = InputTypes[object?.type];
            return instance ? (
              <InputType
                key={field}
                name={field}
                form={form}
                label={object.label}
                required={object.required}
                readOnly={object.readOnly}
                value={instance[field]}
                {...(object.type === "SelectDistrict"
                  ? { city: instance["city"] }
                  : {})}
                {...(object.type === "SelectHoroo"
                  ? { district: instance["district"] }
                  : {})}
              />
            ) : (
              <InputType
                key={field}
                name={field}
                form={form}
                label={object.label}
                required={object.required}
                readOnly={object.readOnly}
                {...(object.type === "SelectDistrict" ? { city } : {})}
                {...(object.type === "SelectHoroo" ? { district } : {})}
              />
            );
          })}
        </Form>
      </Container>
    </AntModal>
  );
};
const Container = styled.div`
  .title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding-bottom: 10px;
    font-weight: 600;
    margin-bottom: 20px;
  }
`;
export default Modal;
