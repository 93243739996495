import { Auth, Hub } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const getAuthContext = React.createContext();
export const AuthContextProvider = (props) => {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [groups, setGroups] = React.useState(null);
  const [allowedModules, setAllowedModules] = React.useState([]);
  const [jwtToken, setJwtToken] = React.useState('');
  const navigate = useNavigate();

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups =
        user.signInUserSession.accessToken.payload['cognito:groups'];
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      setJwtToken(jwtToken);
      setUser(user);
      setGroups(groups);
      setLoggedIn(true);
    } catch (error) {
      navigate('/login');
    }
  };

  const checkUserSession = async () => {
    return await Auth.currentSession();
  };

  useEffect(() => {
    checkUser();
  }, []);

  return (
    <getAuthContext.Provider
      value={{
        jwtToken,
        user,
        groups,
        setUser,
        loggedIn,
        setLoggedIn,
        allowedModules,
        setAllowedModules,
        checkUser,
        checkUserSession,
      }}
    >
      {props.children}
    </getAuthContext.Provider>
  );
};
