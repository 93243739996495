import axios from "utils/axios";
import { Form, Select } from "antd";
import React, { useEffect } from "react";
import configFile from "config.json";
import { NotificationManager } from "react-notifications";

const SelectDistrict = ({
  name,
  form,
  required,
  city: firstCity,
  ...props
}) => {
  const [data, setData] = React.useState([]);
  const [city, setCity] = React.useState(firstCity);
  useEffect(() => {
    fetchData();
    form.setFieldsValue({
      [name]: props.value ?? null,
    });
  }, []);

  useEffect(() => {
    city
      ? fetchData()
      : form.setFieldsValue({
          [name]: null,
        });
  }, [city]);

  useEffect(() => {
    setCity(form.getFieldsValue(["city"]).city);
  });

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${configFile.SERVER_URL_ADDRESS}/getDistricts?city=${city ?? "УБ"}`
      );
      const mappedResult = data.data.map((dist) => ({
        value: dist.key,
        label: `${dist.key}(${dist.doc_count})`,
      }));
      setData(mappedResult);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
    }
  };
  const changeHoroo = () => {
    form.setFieldsValue({
      horoo: "",
    });
  };

  return (
    <Form.Item
      {...props.formItemLayout}
      label="Дүүрэг"
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <Select
        onChange={changeHoroo}
        placeholder="Дүүрэг сонгоно уу"
        style={{ minWidth: "150px", ...props?.style }}
        allowClear
        options={data}
      />
    </Form.Item>
  );
};

export default SelectDistrict;
