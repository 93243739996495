import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import googleMapsApiKey from "utils/googleMapsApiKey";
import styled from "styled-components";
import SearchInput from "components/Form/InputTypes/SearchInput";
import { Button, Form } from "antd";
import axios from "utils/axios";
import configFile from "config.json";
import Marker from "components/Marker";
import {
  SelectCity,
  SelectDistrict,
  SelectHoroo,
  SelectPods,
} from "components/Form/InputTypes";
import { NotificationManager } from "react-notifications";

const containerStyle = {
  width: "100%",
  height: "70vh",
};

const center = {
  lat: 47.915600350786555,
  lng: 106.90147839761418,
};

const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
  });

  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(6);
  const [data, setData] = useState([]);
  const [mapCenter, setMapcenter] = useState(center);
  const [updateValues, setUpdateValues] = useState([]);
  const [form] = Form.useForm();
  const district = Form.useWatch("district", form);
  const city = Form.useWatch("city", form);

  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(mapCenter);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
  useEffect(() => {
    // fetchData();
    setUpdateValues([]);
  }, []);

  const handleSearch = async (e) => {
    setData([]);

    let obj = {};

    Object.keys(form.getFieldValue()).map((field) => {
      let data;
      const fieldData = form.getFieldValue()[field];
      data = fieldData ? fieldData : "";
      obj[field] = data;
    });

    try {
      const {
        data: { data: locations },
      } = await axios.get(
        `${configFile.SERVER_URL_ADDRESS}/findAll?district=${obj.district}&horoo=${obj.horoo}&podph=${obj.pod}&limit=20&address=${obj.address}`
      );
      setData(locations);
      setMapcenter({
        lat: parseFloat(locations[0].lat),
        lng: parseFloat(locations[0].lon),
      });
      setZoom(11);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (data) => {
    setUpdateValues((prevData) => [
      ...prevData.filter((item) => item.id !== data.id),
      data,
    ]);
  };
  const saveData = async () => {
    try {
      Promise.all(
        updateValues.map(async (item) => {
          const data = {
            district: item.district,
            bairname: item.bairname,
            bairnote: item.bairnote,
            city: item.city,
            horoo: item.horoo,
            latitude: `${item.latitude}`,
            longitude: `${item.longitude}`,
            podph: item.podph,
          };
          await axios.post(configFile.SERVER_URL_ADDRESS + "/upsertById", {
            _id: item._id,
            ...data,
          });
        })
      );
      NotificationManager.success("Амжилттай хадгалалаа");
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, "Error");
    }
  };
  return (
    <Container>
      <Form form={form}>
        <div className="head">
          <div className="field">
            <SelectCity form={form} name={"city"} />
          </div>
          <div className="field">
            <SelectDistrict form={form} city={city} name={"district"} />
          </div>
          <div className="field">
            <SelectHoroo form={form} name={"horoo"} district={district} />
          </div>
          <div className="field">
            <SelectPods form={form} name={"pod"} />
          </div>
          <div className="field">
            <SearchInput
              form={form}
              name={"address"}
              enterButton={true}
              handleSearch={handleSearch}
            />
          </div>
        </div>
        {/* <SearchInput form={form} width={400} handleSearch={handleSearch} /> */}
      </Form>
      {isLoaded ? (
        <GoogleMap
          map={null}
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {data.length > 0 &&
            data.map((item, index) => {
              return (
                <div key={index}>
                  <Marker
                    position={{
                      lat: parseFloat(item.lat),
                      lng: parseFloat(item.lon),
                    }}
                    instance={item}
                    onChange={handleChange}
                  />
                </div>
              );
            })}
        </GoogleMap>
      ) : (
        <></>
      )}
      <StyledButton onClick={saveData} type="primary">
        Хадгалах
      </StyledButton>
    </Container>
  );
};
const Container = styled.div`
  .head {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  .field .ant-form-item {
    margin-bottom: 0;
  }
`;
const StyledButton = styled(Button)`
  margin-top: 10px;
`;
export default Map;
