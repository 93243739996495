import { Form, Select } from "antd";
import React, { useEffect } from "react";

const SelectTypeCombo = ({ name, form, required, ...props }) => {
  useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, [props.value]);

  const options = [
    { value: "pizza", label: "pizza" },
    { value: "single", label: "single" },
  ];

  return (
    <Form.Item
      name={name}
      label={props.label}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <Select
        // value={value === 0 ? 'Өнөөдөр' : value === 1 ? 'Маргааш' : null}
        style={{
          width: 110,
        }}
        // allowClear
        options={options}
        {...props}
      />
    </Form.Item>
  );
};
export default SelectTypeCombo;
