import axios from 'utils/axios';
// import SelectHoc from 'contexts/SelectHoc';
import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import configFile from 'config.json';
import { NotificationManager } from 'react-notifications';

const SelectGroups = ({
  groups: firstGroups,
  name,
  form,
  required,
  ...props
}) => {
  const [groups, setGroups] = useState([]);
  // useEffect(() => {
  //   form.setFieldsValue({
  //     [name]: props.value,
  //   });
  // }, []);
  useEffect(() => {
    setGroups(firstGroups);
  }, [firstGroups]);
  return (
    <Form.Item
      style={{ width: '300px' }}
      label='Groups'
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <Select
        optionFilterProp='children'
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '')
            .toLowerCase()
            .localeCompare((optionB?.label ?? '').toLowerCase())
        }
        showSearch
        placeholder='Groups сонгоно уу'
        allowClear
        options={indexedData(groups)}
      />
    </Form.Item>
  );
};
const indexedData = (data) => {
  return data.map((item) => ({
    value: item,
    label: item,
  }));
};
export default SelectGroups;
