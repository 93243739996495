import React, { useEffect } from "react";
import { Form, Input } from "antd";
const { TextArea } = Input;
const InputTextArea = ({ name, form, required, customValidate, ...props }) => {
  useEffect(() => {
    form.setFieldsValue({
      [name]: props.value === null ? "" : props.value,
    });
  }, [props.value]);
  return (
    <Form.Item
      label={props.label}
      name={name}
      rules={[
        { required: required, message: `${props.label} оруулна уу !` },
        customValidate ? { validator: customValidate } : {},
      ]}
      {...props}
    >
      <TextArea {...props} />
    </Form.Item>
  );
};

export default InputTextArea;
