import { NotificationManager } from 'react-notifications';

export const copyToClipBoard = async (copyMe) => {
  try {
    await navigator.clipboard.writeText(copyMe);
    NotificationManager.success('Copied');
    // setCopySuccess('Copied!');
  } catch (err) {
    // setCopySuccess('Failed to copy!');
  }
};
