import { Button, Popconfirm, Table } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { tableMenus as firstColumns } from 'utils/tableModels';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const ItemTable = ({
  dataSource: firstDataSource,
  deleteItem,
  form,
  disabled,
}) => {
  const [data, setData] = useState(firstDataSource);
  const [columns, setColumns] = useState(firstColumns);

  useEffect(() => {
    const mappedData = firstDataSource?.map((item, index) => {
      return { ...item, key: index + 1 };
    });
    setData(mappedData);
    if (!disabled) {
      setColumns([
        ...firstColumns,
        {
          title: '',
          dataIndex: 'actions',
          fixed: 'right',
          rowScope: 'row',

          render: (_, record) => {
            return (
              <Popconfirm
                title='Устгах'
                description='Устгахдаа итгэлтэй байна уу?'
                onConfirm={() => confirm(record)}
                onCancel={() => {}}
                okText='Yes'
                cancelText='No'
                // style={{ display: disabled ? "none" : "block" }}
              >
                <Button
                  size='small'
                  danger
                  type='primary'
                  icon={
                    <DeleteOutlined
                    // style={{ display: disabled ? "none" : "block" }}
                    />
                  }
                >
                  Устгах
                </Button>
              </Popconfirm>
              // </ButtonContainer>
            );
          },
        },
      ]);
    }
  }, [firstDataSource]);
  const confirm = (record) => {
    deleteItem(record);
  };
  return (
    <Container>
      {data && (
        <Table
          pagination={false}
          width={800}
          dataSource={data}
          columns={columns}
          scroll={{
            x: 'max-content',
            // y: 350
          }}
        />
      )}
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  overflow: scroll;
`;
export default ItemTable;
