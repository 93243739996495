import { Form, Modal as AntModal } from 'antd';
import * as InputTypes from 'components/Form/InputTypes';
import styled from 'styled-components';
const Modal = ({
  width,
  saveButtonText,
  cancelButtonText,
  isShowModal,
  setIsShowModal,
  model,
  instance,
  handleSave,
  title,
  layout,
}) => {
  const [form] = Form.useForm();
  const handleValidate = async () => {
    try {
      let values = await form.validateFields();
      handleSave(values);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = async () => {
    setIsShowModal(false);
  };
  return (
    <AntModal
      width={width}
      onOk={handleValidate}
      onCancel={handleCancel}
      open={isShowModal}
      okText={saveButtonText}
      cancelText={cancelButtonText}
      okType='primary'
    >
      <Container>
        <div className='title'>{title}</div>
        <Form form={form} {...layout}>
          {Object.keys(model).map((field) => {
            const object = model[field];
            const InputType = InputTypes[object.type];
            return (
              <InputType
                key={field}
                name={field}
                label={object.label}
                required={object.required}
                readOnly={object.readOnly}
                value={instance[field]}
                form={form}
                style={{ width: '100%' }}
              />
            );
          })}
        </Form>
      </Container>
    </AntModal>
  );
};
const Container = styled.div`
  .title {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    display: flex;
  }
`;
export default Modal;
