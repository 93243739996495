import styled from 'styled-components';

const Logo = () => {
  return (
    <Container>
      <img id='logo' src='/pizzahut.png' />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 16px;
  img {
    width: 100%;
  }
`;
export default Logo;
