import { Button, Input, Space, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { tableMenus as firstColumns } from 'utils/tableModels';
import { SearchOutlined } from '@ant-design/icons';
import ExampleTable from 'components/Example';
// import Highlighter from 'react-highlight-words';
const MenuTable = ({ height, dataSource: firstDataSource, menu }) => {
  const [columns, setColumns] = useState(firstColumns);
  const [filteredData, setFilteredData] = useState(firstDataSource);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchButtonRef = useRef(null);
  const resetButtonRef = useRef(null);
  const searchInput = useRef(null);

  useEffect(() => {
    const ll = firstDataSource.map((record, index) => {
      const className = rowClassName(record, index, duplicatedRowIndices);
      return (record = { ...record, class: className });
    });

    setFilteredData(ll);
  }, [firstDataSource]);

  useEffect(() => {
    let newCols = [];
    if (menu === 'Pizza') {
      newCols = [
        {
          title: 'name',
          dataIndex: 'name',
          rowScope: 'row',
        },
        {
          title: 'pizzaBase',
          dataIndex: 'pizzaBase',
        },
        {
          title: 'pizzaSize',
          dataIndex: 'pizzaSize',
        },
      ];
    }
    newCols = [...newCols, ...firstColumns];
    newCols[0] = { ...newCols[0], fixed: 'left' };
    columnAddWithFilters(newCols);
    return () => {
      setColumns(firstColumns);
    };
  }, [menu]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    // const filteredRows = firstDataSource.filter((row) =>
    //   Object.values(row).some((cell) => {
    //     return String(cell)
    //       .toLowerCase()
    //       .includes(selectedKeys[0] ? selectedKeys[0].toLowerCase() : cell);
    //   })
    // );
    // rowClassName(record, index, duplicatedRowIndices)
    // setFilteredData(filteredRows);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={(e) =>
            handleSearch(selectedKeys, confirm, dataIndex, e)
          }
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            ref={searchButtonRef}
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            ref={resetButtonRef}
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) => text,
  });

  const columnAddWithFilters = (cols) => {
    const columnWithFilters = cols.map((column) => {
      return { ...column, ...getColumnSearchProps(column.dataIndex) };
    });
    setColumns(columnWithFilters);
  };

  const duplicatedRowIndices = firstDataSource.reduce(
    (indices, record, index) => {
      if (checkForDuplicates('miMasterObjNum', index, firstDataSource)) {
        indices.push(index);
      }
      return indices;
    },
    []
  );
  console.log(height);
  return (
    <Container>
      <StyledTable
        tableLayout='auto'
        columns={columns}
        dataSource={filteredData}
        rowClassName={(record, index) => {
          return record.class;
        }}
        pagination={false}
        // style={{ height: '400px' }}
        scroll={{
          // y: 400 ,
          x: 'max-content',
        }}
      />
    </Container>
  );
};
const Container = styled.div`
  // height: 400px; /* Set the desired height in pixels */
`;
const StyledTable = styled(Table)`
  width: 100%;
  // tbody {
  //   height: 400px;
  // }
  .duplicated-row,
  .duplicated-row:hover {
    .ant-table-cell {
      background-color: rgb(255, 160, 122) !important;
    }
  }
`;
const checkForDuplicates = (field, index, arr) => {
  return arr.findIndex((item) => item[field] === arr[index][field]) !== index;
};

const rowClassName = (record, index, duplicatedRowIndices) => {
  // console.log(duplicatedRowIndices);
  return duplicatedRowIndices.includes(index) ? 'duplicated-row' : '';
  // return duplicatedRowIndices;
};

export default MenuTable;
