import { Form, Select } from 'antd';
import React, { useEffect } from 'react';

const SelectTimeShipt = ({ name, form, required, ...props }) => {
  useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);

  const options = [
    { value: 0, label: 'Өнөөдөр' },
    { value: 1, label: 'Маргааш' },
  ];

  return (
    <Form.Item
      name={name}
      label={props.label}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <Select
        // value={value === 0 ? 'Өнөөдөр' : value === 1 ? 'Маргааш' : null}
        style={{
          width: 110,
        }}
        // allowClear
        options={options}
      />
    </Form.Item>
  );
};
export default SelectTimeShipt;
