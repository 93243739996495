import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Space, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
const UploadImage = ({ name, form, required, label, ...props }) => {
  const formUrl = Form.useWatch('url', form);
  const [uploadFile, setUploadFile] = useState([]);
  useEffect(() => {
    setUploadFile([]);
  }, [formUrl]);
  useEffect(() => {
    form.setFieldValue('files', uploadFile[0]?.originFileObj);
  }, [uploadFile]);

  const handleChange = (info) => {
    if (info.file.error) {
      setUploadFile([]);
    } else setUploadFile(info.fileList);
  };
  const dummyRequest = ({ file, onSuccess, onError }) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      setUploadFile([]);
      onError('error');
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 10MB!');
      onError('size');
      return;
    }
    onSuccess('ok');
  };
  return (
    <Form.Item
      valuePropName={name}
      // width={100}
      style={{ width: '100%' }}
      name={name}
      rules={[
        { required: required, message: `${name} оруулна уу !` },
        {
          message: 'URL эсвэл зураг оруулна уу',
          validator: (_, value) => {
            if (
              (formUrl === undefined || formUrl === '') &&
              value === undefined
            ) {
              return Promise.reject();
            } else {
              return Promise.resolve();
            }
          },
        },
      ]}
    >
      <Upload
        customRequest={dummyRequest}
        listType='picture'
        maxCount={1}
        disabled={formUrl !== '' && formUrl !== undefined}
        onChange={handleChange}
        fileList={uploadFile}
        style={{ width: '100%' }}
      >
        <Button
          style={{ width: '100%' }}
          icon={<UploadOutlined />}
          disabled={formUrl !== '' && formUrl !== undefined}
        >
          Upload
        </Button>
      </Upload>
    </Form.Item>
  );
};

export default UploadImage;
