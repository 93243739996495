import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';

const SelectCombo = ({ name, form, data: firstData, required, ...props }) => {
  const [data, setData] = useState(firstData ? firstData : []);
  useEffect(() => {
    const result = [];
    const map = new Map();
    for (const item of data) {
      if (!map.has(item.miMasterObjNum)) {
        map.set(item.miMasterObjNum, true); // set any value to Map
        result.push(item);
      }
    }

    const mappedResult = result.map((item, index) => ({
      value: item.miMasterObjNum,
      label: item.masterName,
      longDescriptor_stringText: item.longDescriptor_stringText
    }));
    setData(mappedResult);

    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);
  const changeData = (value, _) => {
    form.setFieldsValue({ masterName: _.label,
       longDescriptor_stringText : _.longDescriptor_stringText });
  };
  return (
    <Form.Item
      name={name}
      label={props.label}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <Select
        // value={value === 0 ? 'Өнөөдөр' : value === 1 ? 'Маргааш' : null}
        style={{
          width: '200px',
        }}
        {...props}
        // allowClear
        options={data}
        onChange={changeData}
      />
    </Form.Item>
  );
};
export default SelectCombo;
