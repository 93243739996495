import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import configFile from "config.json";
import axios from "utils/axios";
import styled from "styled-components";
import { SelectStore } from "components/Form/InputTypes";
import { Button, Form } from "antd";
import MenuTable from "components/Table/ComboTable";
import { tableCombo as columns } from "utils/tableModels";
import { ShowCombo } from "components/Modal/Combo";
import { PlusOutlined } from "@ant-design/icons";

const Combo = () => {
  const [menus, setMenus] = useState(null);
  const [form] = Form.useForm();
  const [store, setStore] = useState();
  const [tableData, setTableData] = useState([]);
  const [type, setType] = useState(null);
  const [instance, setInstance] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   if (store) fetchData();
  // }, [menus]);

  useEffect(() => {
    setMenus([]);
    setTableData([]);
    if (store) {
      fetchDataGetMenus();
    }
  }, [store]);

  const fetchDataGetMenus = async () => {
    try {
      const {
        data: { data },
      } = await axios.post(`${configFile.PROD_SERVER_URL}/menu/getMenus`, {
        storeName: store,
      });
      setMenus(data);
      fetchData();
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
      console.log(error);
    }
  };
  const fetchData = async () => {
    try {
      setTimeout(async () => {
        const { data } = await axios.post(
          `${configFile.PROD_SERVER_URL}/menu/getComboMenus`,
          {
            storeName: store,
          }
        );
        // console.log(menus);
        // if (menus.length === 0 || !menus) {
        //   setTableData([]);
        // } else {

        // mappedData.sort((a,b) => a.masterName - b.masterName)
        data.data.sort(function (a, b) {
          const nameA = a.masterName.toUpperCase(); // ignore upper and lowercase
          const nameB = b.masterName.toUpperCase(); // ignore upper and lowercase

          // sort in an ascending order
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        });
        const mappedData = data.data.map((item, index) => {
          return {
            ...item,
            key: index + 1,
          };
        });
        setTableData(mappedData);
      }, 500);

      // }
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
      console.log(error);
    }
  };
  const handleStore = (item) => {
    setStore(item);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleAction = (instance, actionType) => {
    setInstance(instance);
    setType(actionType);
    showModal();
  };
  const handleCreate = () => {
    setType("create");
    showModal();
  };
  const deleteCombo = async (record) => {
    try {
      await axios.post(`${configFile.PROD_SERVER_URL}/menu/deleteComboMenu`, {
        id: record.id,
      });
      fetchData();
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
    }
  };
  return (
    <Form form={form}>
      <Container>
        <TopHead>
          <SelectStore form={form} name="store" onChange={handleStore} />
          {store && (
            <Button
              type="primary"
              onClick={handleCreate}
              icon={<PlusOutlined />}
            >
              Шинээр үүсгэх
            </Button>
          )}
        </TopHead>
        {/* {store && ( */}
        <MenuTable
          columns={columns}
          dataSource={tableData}
          handleAction={handleAction}
          menus={menus}
          deleteCombo={deleteCombo}
          fetchData={fetchData}
        />
        {/* )} */}
        {isModalOpen && type === "edit" && (
          <ShowCombo
            // width={800}
            isShowModal={isModalOpen}
            setIsShowModal={setIsModalOpen}
            cancelButtonText="Хаах"
            okButtonText="Хадгалах"
            instance={instance}
            title={`${instance["masterName"]} багц засах`}
            rawMainItems={instance.rawMainItems}
            rawSideItems={instance.rawSideItems}
            type={type}
            store={store}
            menus={menus}
            fetchData={fetchData}
          />
        )}
        {isModalOpen && type === "create" && (
          <ShowCombo
            // width={800}
            isShowModal={isModalOpen}
            setIsShowModal={setIsModalOpen}
            cancelButtonText="Хаах"
            okButtonText="Хадгалах"
            title={`Шинэ багц үүсгэх`}
            // rawMainItems={instance.rawMainItems}
            // rawSideItems={instance.rawSideItems}
            type={type}
            store={store}
            menus={menus}
            fetchData={fetchData}
          />
        )}
        {isModalOpen && type === "show" && (
          <ShowCombo
            // width={800}
            isShowModal={isModalOpen}
            setIsShowModal={setIsModalOpen}
            okButtonText="Ok"
            cancelButtonText="Хаах"
            instance={instance}
            title={`${instance["masterName"]} мэдээлэл`}
            rawMainItems={instance.rawMainItems}
            rawSideItems={instance.rawSideItems}
            type={type}
            store={store}
            menus={menus}
          />
        )}
      </Container>
    </Form>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const TopHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export default Combo;
