import axios from 'axios';
import { Auth } from 'aws-amplify';
const instance = axios.create();
instance.interceptors.request.use(
  async (config) => {
    try {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken().getJwtToken();
      config.headers.Authorization = `Bearer ${accessToken}`;
    } catch (error) {
      Promise.reject(error);
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export default instance;
