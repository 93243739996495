import { InfoWindow, Marker } from '@react-google-maps/api';
import { useState } from 'react';

const Markers = ({
  position: firstPosition,
  instance,
  onChange
}) => {
  const [position, setPosition] = useState(firstPosition);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleMarkerClick = () => {
    setSelectedMarker(instance);
  };
  const handleMarkerDragEnd = (ev) => {
    setPosition({
      lat: ev.latLng.lat(),
      lng: ev.latLng.lng(),
    });
    onChange &&
      onChange({
        ...instance,
        latitude: ev.latLng.lat(),
        longitude: ev.latLng.lng(),
      });
  };
  return (
    <>
      <Marker
        position={position}
        onClick={handleMarkerClick}
        draggable={true}
        onDragEnd={handleMarkerDragEnd}
      />
      {selectedMarker && (
        <InfoWindow
          position={position}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div>{selectedMarker.bairname}</div>
        </InfoWindow>
      )}
    </>
  );
};
export default Markers;
