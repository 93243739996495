import axios from 'utils/axios';
// import SelectHoc from 'contexts/SelectHoc';
import { AutoComplete, Form, Input } from 'antd';
import React from 'react';
import configFile from 'config.json';
import { NotificationManager } from 'react-notifications';

const SelectPods = ({ name, form, required, ...props }) => {
  const [data, setData] = React.useState([]);
  const [searchData, setSearchData] = React.useState([]);
  React.useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${configFile.SERVER_URL_ADDRESS}/getPods`
      );
      const mappedResult = data.data.map((dist) => ({
        value: dist.key,
        label: `${dist.key}(${dist.doc_count})`,
      }));
      setData(mappedResult);
      setSearchData(mappedResult);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
    }
  };
  const handleSearch = (value) => {
    value = value.toLowerCase();
    form.setFieldsValue({
      [name]: value,
    });
    const searchedData = data.filter((item) => {
      const val = item.value.toLowerCase();
      if (val.includes(value)) {
        return item;
      }
    });
    setSearchData(searchedData);
  };
  const onSelect = (value) => {
    console.log('onSelect', value);
  };

  return (
    <Form.Item
      label='Под'
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <AutoComplete
        allowClear
        dropdownMatchSelectWidth={252}
        style={{
          width: 300,
        }}
        options={searchData}
        onSelect={onSelect}
        onSearch={handleSearch}
      >
        <Input placeholder='ПОД сонгоно уу' />
      </AutoComplete>
    </Form.Item>
  );
};

export default SelectPods;
