import React, { useCallback, useEffect, useState } from 'react';
//1km дотор байгаа хамгийн ойрхон 20 хаяг
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import googleMapsApiKey from 'utils/googleMapsApiKey';
import styled from 'styled-components';
import { Avatar, Button, Form, List } from 'antd';
import axios from 'utils/axios';
import configFile from 'config.json';
import { MdLocationPin } from 'react-icons/md';

const containerStyle = {
  width: '100%',
  height: '90vh',
};

const center = {
  lat: 47.915571587860946,
  lng: 106.9014462111073,
};

const SearchLocation = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
  });

  const [map, setMap] = useState(null);
  const [data, setData] = useState([]);
  const [position, setPosition] = useState(center);
  // const [data, setData] = useState([]);
  const [mapCenter, setMapcenter] = useState(center);
  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleSearch = async (e) => {
    try {
      const { data } = await axios.get(
        `${configFile.SERVER_URL_ADDRESS}/searchByLocation?lat=${position.lat}&lon=${position.lng}`
      );
      setData(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePosition = (ev) => {
    setPosition({ lat: ev.latLng.lat(), lng: ev.latLng.lng() });
  };
  return (
    <Container>
      {isLoaded ? (
        <GoogleMap
          // style={{ width: '40%' }}
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={14}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <MarkerF
            position={mapCenter}
            draggable={true}
            onDragEnd={handlePosition}
          />
        </GoogleMap>
      ) : (
        <></>
      )}
      <ContainerRight>
        <Button type='primary' onClick={handleSearch}>
          Хайх
        </Button>
        <SearchedItems>
          <List
            itemLayout='horizontal'
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<MdLocationPin color='red' />}
                  title={item.full_address}
                />
              </List.Item>
            )}
          />
        </SearchedItems>
      </ContainerRight>
    </Container>
  );
};
const ContainerRight = styled.div`
  width: 30%;
  height: 90vh;
  overflow-y: scroll;
  padding: 20px;
`;
const SearchedItems = styled.div`
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  // font-size: 12px;
  // font-weight: 600;
`;
const Container = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
  background: white;
`;
export default SearchLocation;
