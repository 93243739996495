import { Button, Form } from 'antd';
import SelectGroups from 'components/Form/InputTypes/SelectGroups';
import Modal from 'components/Modal/User/UserModal';
import Table from 'components/Table/UsersTable';
import configFile from 'config.json';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import axios from 'utils/axios';
import { tableUser as columns } from 'utils/tableModels';
import { Excel } from 'antd-table-saveas-excel';
import withLoader from 'components/WithLoader';

//excel
//order,search,filter
//

const Users = () => {
  const [users, setUsers] = useState([]);
  const [tableUsers, setTableUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [instance, setInstance] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const selectGroup = Form.useWatch('groups', form);

  useEffect(() => {
    fetchGroupsData();
  }, []);

  useEffect(() => {
    if (selectGroup) {
      listUsersInGroup(selectGroup);
    } else if (selectGroup !== undefined) {
      fetchUsersData();
    }
  }, [selectGroup]);
  const listUsersInGroup = async (group) => {
    try {
      const {
        data: { data: usersInGroup },
      } = await axios.post(
        `${configFile.PROD_SERVER_URL}/user/listUsersInGroup`,
        { group }
      );
      setTableUsers(usersInGroup);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
      console.log(error);
    }
  };

  const fetchUsersData = async () => {
    try {
      const {
        data: { data: users },
      } = await axios.post(`${configFile.PROD_SERVER_URL}/user/getUsers`);
      setUsers(users);
      setTableUsers(users);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
      console.log(error);
    }
  };
  const fetchGroupsData = async () => {
    try {
      const {
        data: { data: groups },
      } = await axios.post(`${configFile.PROD_SERVER_URL}/user/getGroups`);
      setGroups(groups);
      fetchUsersData();
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
      console.log(error);
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleInstance = (instance) => {
    setInstance(instance);
    showModal();
  };
  const handleSave = (data) => {
    console.log({ data, instance });
  };
  const handleConfirmSignup = async () => {
    try {
      const user = await axios.post(
        `${configFile.PROD_SERVER_URL}/user/confirmSignUp`,
        {
          username: instance.username,
        }
      );
      NotificationManager.success('Амжилттай хадгаллаа');
      fetchUsersData();
      setIsModalOpen(false);
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, 'Error');
      console.log(error);
    }
  };
  const handleDisableUser = async () => {
    try {
      const user = await axios.post(
        `${configFile.PROD_SERVER_URL}/user/disableUser`,
        {
          username: instance.username,
        }
      );
      NotificationManager.success('Амжилттай хадгаллаа');
      fetchUsersData();
      setIsModalOpen(false);
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, 'Error');
      console.log(error);
    }
  };
  const handleEnableUser = async () => {
    try {
      const user = await axios.post(
        `${configFile.PROD_SERVER_URL}/user/enableUser`,
        {
          username: instance.username,
        }
      );
      NotificationManager.success('Амжилттай хадгаллаа');
      fetchUsersData();
      setIsModalOpen(false);
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, 'Error');
      console.log(error);
    }
  };
  const onSearch = (val) => {
    const value = val?.toLowerCase();
    const searchedData = users.filter((item) => {
      const user = item['username']?.toLowerCase();
      if (user.includes(value)) {
        return item;
      }
    });
    setTableUsers(searchedData);
  };
  const saveExcel = () => {
    const excel = new Excel();
    excel
      .addSheet('Sheet')
      .addColumns(columns)
      .addDataSource(tableUsers, {
        str2Percent: true,
      })
      .saveAs('Excel.xlsx');
  };
  return (
    <Container>
      {/* {users.length > 0 && ( */}
      <Form form={form}>
        <div className='head'>
          <SelectGroups groups={groups} form={form} name='groups' />
        </div>
        <Button type='primary' onClick={saveExcel}>
          Excel
        </Button>
      </Form>

      <Table
        users={tableUsers}
        columns={columns}
        handleActionButton={handleInstance}
      />
      {/* )} */}
      {isModalOpen && (
        <Modal
          isShowModal={isModalOpen}
          setIsShowModal={setIsModalOpen}
          saveButtonText='Хадгалах'
          cancelButtonText='Хаах'
          handleSave={handleSave}
          instance={instance}
          title='хэрэглэгчийн мэдээлэл засах'
          groups={groups}
          handleConfirmSignup={handleConfirmSignup}
          handleDisableUser={handleDisableUser}
          handleEnableUser={handleEnableUser}
        />
      )}
    </Container>
  );
};
const Container = styled.div`
  .head {
    display: flex;
  }
`;
export default Users;
