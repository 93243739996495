import { Form, Input } from 'antd';
import { useEffect } from 'react';
const { Search } = Input;
const SearchInput = ({
  name,
  form,
  required,
  handleSearch,
  refSearch,
  ...props
}) => {
  useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);

  return (
    <Form.Item
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
      label='Хайх'
    >
      <Search
        ref={refSearch}
        style={{ width: props.width }}
        placeholder='Хайх хаягаа оруулна уу'
        enterButton
        allowClear
        onPressEnter={handleSearch}
        onSearch={handleSearch}
      />
    </Form.Item>
  );
};

export default SearchInput;
