import { PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import Modal from 'components/Modal/ImageModal';
import Table from 'components/Table/ImagesTable';
import configFile from 'config';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import axios from 'utils/axios';
import { formImage } from 'utils/formModels';
import { tableImages as columns } from 'utils/tableModels';
const Image = () => {
  const [allImages, setAllImages] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [instance, setInstance] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const {
        data: { data: images },
      } = await axios.post(`${configFile.PROD_SERVER_URL}/menu/getImages`);
      setAllImages(images);
      setTableData(images);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
      console.log(error);
    }
  };
  const showModal = () => {
    setIsShowModal(true);
  };
  const handleInstance = (instance) => {
    setType('edit');
    setInstance(instance);
    showModal();
  };
  const handleCreate = () => {
    setInstance(null);
    setType('create');
    showModal();
  };
  const deleteHandle = async (data) => {
    const obj = {
      name: data.name,
      url: data.url,
    };
    try {
      await axios.post(`${configFile.PROD_SERVER_URL}/menu/deleteImage`, obj);
      NotificationManager.success('Амжилттай устгалаа');
      setIsShowModal(false);
      fetchData();
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
      console.log(error);
    }
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleSaveData = async (datas) => {
    const data = {
      name: datas.name,
    };
    if (datas.files) {
      data.imgData = await toBase64(datas.files);
    } else {
      data.url = datas.url;
    }
    try {
      switch (type) {
        case 'create':
          await axios.post(
            `${configFile.PROD_SERVER_URL}/menu/createImage`,
            data
          );
          NotificationManager.success('Амжилттай үүсгэлээ');
          setIsShowModal(false);
          fetchData();
          break;
        case 'edit':
          await axios.post(
            `${configFile.PROD_SERVER_URL}/menu/updateImage`,
            data
          );
          NotificationManager.success('Амжилттай хадгаллаа');
          setIsShowModal(false);
          fetchData();
          break;
        default:
          break;
      }
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
      console.log(error);
    }
  };
  const onSearch = (val) => {
    const value = val?.toLowerCase();
    const searchedData = allImages.filter((item) => {
      const image = item['name']?.toLowerCase();
      if (image.includes(value)) {
        return item;
      }
    });
    setTableData(searchedData);
  };

  return (
    <Container>
      <div className='head'>
        <Input.Search
          size='medium'
          placeholder='Master Name хайх'
          onChange={(ev) => {
            onSearch(ev.target.value);
          }}
          style={{ width: '200px' }}
        />
        <Button type='primary' onClick={handleCreate} icon={<PlusOutlined />}>
          Шинээр үүсгэх
        </Button>
      </div>
      <Table
        actionHandle={handleInstance}
        columns={columns}
        dataSource={tableData}
        deleteAction={deleteHandle}
      />
      {isShowModal && (
        <Modal
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          saveButtonText='Хадгалах'
          cancelButtonText='Буцах'
          model={formImage}
          title={
            type === 'create' ? 'Шинээр үүсгэх' : `${instance?.name} засах`
          }
          handleSave={handleSaveData}
          type={type}
          instance={instance}
        />
      )}
    </Container>
  );
};
const Container = styled.div`
  .head {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
  }
`;
export default Image;
