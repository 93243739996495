import React from 'react';
import styledComponents from 'styled-components';
import { BsKeyFill } from 'react-icons/bs';
import { EmailInput, PasswordInput } from './Form/InputTypes';
import { Button, Form } from 'antd';
import styled from 'styled-components';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';

const Login = ({ handleSubmit }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleButton = async () => {
    try {
      const data = await form.validateFields();
      handleSubmit(data);
    } catch (error) {
      //   NotificationManager.error('Мэйл хаяг эсвэл Нууц үг буруу байна');
    }
  };
  return (
    <Form form={form}>
      <Container>
        <img src='/logo.png' />
        <h2>Нэвтрэх</h2>
        <EmailInput required={true} name='email' form={form} />
        <PasswordInput
          required={true}
          name='password'
          placeholder='Нууц үгээ оруулна уу'
          message='Нууц үгээ оруулна уу !'
          form={form}
        />
        <div className='btm'>
          <Button
            className={'login'}
            onClick={(e) => {
              e.preventDefault();
              handleButton();
            }}
            type='primary'
            icon={<BsKeyFill />}
            size={'medium'}
            htmlType='submit'
          >
            Нэвтрэх
          </Button>
          <span>эсвэл</span>
          <Button
            className={'black'}
            onClick={(e) => {
              e.preventDefault();
              navigate('/register');
              // handleButton();
            }}
            type='primary'
            icon={<BsKeyFill />}
            size={'medium'}
            htmlType='submit'
            styles={{ background: 'black' }}
          >
            Бүртгүүлэх
          </Button>
        </div>
      </Container>
    </Form>
  );
};
export default Login;
const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 220px;
  margin: auto;
  img {
    width: 100px;
  }
  h2 {
    text-align: center;
  }
  .btm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    > * {
      width: 100%;
    }
    > span {
      text-align: center;
      font-size: 12px;
    }
    .login {
      background: ${({ theme }) => theme.color};
    }
    .black {
      background: ${({ theme }) => theme.dark};
    }
  }
`;
