import axios from 'utils/axios';
import { Form, Select, TreeSelect } from 'antd';
import React, { useEffect } from 'react';
import configFile from 'config.json';
import { NotificationManager } from 'react-notifications';

const SelectMultiple = ({ name, form, required, ...props }) => {
  console.log(name);
  const [data, setData] = React.useState([]);
  const type = Form.useWatch('type', form);
  useEffect(() => {
    fetchData();
    // form.setFieldsValue({
    //   [name]: props.value,
    // });
  }, []);
  useEffect(() => {
    console.log(type);
    // fetchData();
    // form.setFieldsValue({
    //   [name]: props.value,
    // });
  }, [type]);

  const fetchData = async () => {
    try {
      const result = await axios.post(
        `${configFile.PROD_SERVER_URL}/menu/getMenus`,
        {
          storeName: 'PH99-MOCKSTORE1',
        }
      );
      let datas = [];
      Object.keys(result.data.data).map((field, i) => {
        if (field !== 'Condiments' && field !== 'Pizza' && field !== 'Combo') {
          const obj = {
            title: field,
            key: Math.random(),
            disableCheckbox: (node) => node.disabled,
            children: [
              ...result.data.data[field].map((item) => {
                return {
                  title: item.masterName,
                  value: item.menuItemDefID,
                  key: Math.random(),
                };
              }),
            ],
          };
          datas.push(obj);
        }
      });
      setData(datas);
      //   const mappedResult = data.data.map((dist) => ({
      //     value: dist.key,
      //     label: `${dist.key}(${dist.doc_count})`,
      //   }));
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, 'Error', 5000);
    }
  };
  const onChange = (value, _) => {
    console.log({ value, _ });
    // form.setFieldsValue({
    //   horoo: '',
    // });
  };

  return (
    <Form.Item
      {...props.formItemLayout}
      label='Бүтээгдэхүүн'
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      {console.log(data)}
      <TreeSelect
        onChange={onChange}
        placeholder='Бүтээгдэхүүн сонгоно уу'
        allowClear
        options={data}
      />
    </Form.Item>
  );
};

export default SelectMultiple;
