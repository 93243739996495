import { EditOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import Modal from 'components/Modal/StoreModal';
import axios from 'utils/axios';
import EditStore from 'components/EditStore';
import Table from 'components/Table/StoreTable';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import { formEditStore, formEditPos } from 'utils/formModels';
import { tableStores as columns } from 'utils/tableModels';
import configFile from 'config.json';
import { SelectStore } from 'components/Form/InputTypes';
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 12,
  },
};
const Store = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [instance, setInstance] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleSave = async (data) => {
    try {
      switch (type) {
        case 'edit':
          await axios.post(`${configFile.PROD_SERVER_URL}/store/update`, {
            ...data,
          });
          NotificationManager.success('Амжилттай хадгаллаа');
          break;
        case 'pos':
          await axios.post(`${configFile.PROD_SERVER_URL}/store/updateConfig`, {
            storeName: instance.storeName,
            ...data,
          });
          break;
        default:
          break;
      }
      setIsModalOpen(false);
      fetchData();
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, 'Error');
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        `${configFile.PROD_SERVER_URL}/store/list`
      );
      const mappedData = data.data.map((item, index) => {
        return { ...item, key: index + 1 };
      });
      setData(mappedData);
      setTableData(mappedData);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
      console.log(error);
    }
  };
  const handleInstance = (instance) => {
    setInstance(instance);
    showModal();
  };
  const onChangeStore = (store) => {
    if (store) {
      const searchedData = data.filter((item) => {
        if (item.storeName === store) {
          return item;
        }
      });
      setTableData(searchedData);
    } else {
      setTableData(data);
    }
  };

  return (
    <Container>
      <Form form={form}>
        <div className='head'>
          {data.length > 0 && (
            <SelectStore form={form} name='store' onChange={onChangeStore} />
          )}
          <Table
            handleActionButton={handleInstance}
            setType={setType}
            columns={columns}
            dataSource={tableData}
          />
        </div>
      </Form>

      {isModalOpen && (
        <Modal
          width={800}
          isShowModal={isModalOpen}
          setIsShowModal={setIsModalOpen}
          saveButtonText='Хадгалах'
          cancelButtonText='Буцах'
          model={type === 'edit' ? formEditStore : formEditPos}
          handleSave={handleSave}
          instance={instance}
          title={type === 'edit' ? 'Салбарын мэдээлэл засах' : 'Пос тохиргоо'}
          layout={layout}
        />
      )}
    </Container>
  );
};
const Container = styled.div`
  background-color: #fff;
  .head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .modal {
    width: 600px;
  }
`;
export default Store;
