import axios from 'utils/axios';
import Modal from 'components/Modal/StoreModal';
import Table from 'components/Table/StoreTable';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';
import { formEditPod } from 'utils/formModels';
import { tablePods as columns } from 'utils/tableModels';
import configFile from 'config.json';
import { SelectStore } from 'components/Form/InputTypes';
import { Form, Input } from 'antd';
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};
const Pod = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [instance, setInstance] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    fetchData();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        `${configFile.PROD_SERVER_URL}/store/pod/list`
      );
      const mappedData = data.data.map((item, index) => {
        return { ...item, key: index + 1 };
      });
      setData(mappedData);
      setTableData(mappedData);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
      console.log(error);
    }
  };
  const handleInstance = (instance) => {
    setInstance(instance);
    showModal();
  };
  const handleSave = async (data) => {
    try {
      await axios.post(`${configFile.PROD_SERVER_URL}/store/pod/update`, {
        ...data,
      });
      await fetchData();
      NotificationManager.success('Амжилттай хадгаллаа');
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, 'Error');
    }
    setIsModalOpen(false);
  };

  const onSearch = (val, field) => {
    const value = val?.toLowerCase();
    const searchedData = data.filter((item) => {
      const pod = item[field]?.toLowerCase();
      if (pod.includes(value)) {
        return item;
      }
    });
    setTableData(searchedData);
  };
  const onChangeStore = (value) => {
    onSearch(value, 'storeName');
  };
  return (
    <Container>
      <Form form={form}>
        <div className='head'>
          <Input.Search
            size='medium'
            placeholder='Под хайх'
            onChange={(ev) => {
              onSearch(ev.target.value, 'podCode');
            }}
            style={{ width: '200px' }}
          />
          {data.length > 0 && (
            <SelectStore form={form} name='store' onChange={onChangeStore} />
          )}
        </div>
      </Form>
      <Table
        columns={columns}
        dataSource={tableData}
        handleActionButton={handleInstance}
        setType={setType}
        disablePos={true}
      />
      {isModalOpen && type === 'edit' && (
        <Modal
          width={800}
          isShowModal={isModalOpen}
          setIsShowModal={setIsModalOpen}
          saveButtonText='Хадгалах'
          cancelButtonText='Буцах'
          model={formEditPod}
          handleSave={handleSave}
          instance={instance}
          title='Под мэдээлэл засах'
          layout={layout}
        />
      )}
    </Container>
  );
};
const Container = styled.div`
  background-color: #fff;
  .modal {
    width: 600px;
  }
  .head {
    display: flex;
    gap: 30px;
  }
`;
export default Pod;
