import React from 'react';
import { Input } from 'antd';
const { TextArea } = Input;

const Area = ({ children, required, onChange, onChangeClean, ...props }) => {
  const forwardChange = (e) => {
    onChange && onChange(e);
    onChangeClean && onChangeClean(e.target.value);
  };
  return (
    <TextArea
      rows={3}
      onChange={forwardChange}
      required={required}
      {...props}
    />
    // <Container rows={4} onChange={forwardChange} required={required} {...props}>{children}</Container>
  );
};

export default Area;
