import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthContext } from 'contexts/auth';

const Logout = () => {
  const { setLoggedIn } = React.useContext(getAuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    signOut();
  }, []);
  const signOut = async () => {
    try {
      await Auth.signOut();
      setLoggedIn(false);
      navigate('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };
};
export default Logout;
