import { Button, Popconfirm, Table as AntTable } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const Table = ({
  columns: prevColumns,
  dataSource,
  handleActionButton,
  deleteHandle,
}) => {
  useEffect(() => {
    handleColumns();
  }, []);
  const [columns, setColmns] = useState(prevColumns);
  const confirm = (record) => {
    deleteHandle(record);
  };
  const handleColumns = () => {
    setColmns([
      ...prevColumns,
      {
        title: '',
        dataIndex: 'actions',
        fixed: 'right',
        render: (_, record) => {
          return (
            <ButtonContainer>
              <Button
                type='primary'
                onClick={() => {
                  handleActionButton(record);
                }}
                icon={<EditOutlined />}
              >
                Засах
              </Button>
              <Popconfirm
                title='Устгах'
                description='Устгахдаа итгэлтэй байна уу?'
                onConfirm={() => confirm(record)}
                onCancel={() => {}}
                okText='Yes'
                cancelText='No'
              >
                <Button danger type='primary' icon={<DeleteOutlined />}>
                  Устгах
                </Button>
              </Popconfirm>
            </ButtonContainer>
          );
        },
      },
    ]);
  };
  return (
    <StyledTable
      pagination={false}
      bordered={false}
      columns={columns}
      dataSource={dataSource}
      scroll={{ x: 'max-content' }}
    />
  );
};
const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;
const StyledTable = styled(AntTable)`
  // thead tr {
  //   font-size: 12px;
  // }
  // tbody tr {
  //   font-size: 12px;
  //   button {
  //     font-size: 12px;
  //   }
  // }
  .success {
    color: green;
  }
  .danger {
    color: red;
  }
  .actions {
    width: 50px;
    display: flex;
    gap: 10px;
    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
`;
export default Table;
