import { Button, Table as AntTable } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';

const Table = ({
  columns: prevColumns,
  handleActionButton,
  setType,
  dataSource,
  disablePos,
}) => {
  useEffect(() => {
    handleColumns();
  }, []);
  const [columns, setColmns] = useState(prevColumns);

  const handleColumns = () => {
    setColmns([
      ...prevColumns,
      {
        title: '',
        dataIndex: 'actions',
        fixed: 'right',
        render: (_, record) => {
          return (
            <div className='actions'>
              <Button
                type='primary'
                onClick={() => {
                  handleActionButton(record);
                  setType('edit');
                }}
                icon={<EditOutlined />}
              >
                Засах
              </Button>
              {!disablePos && (
                <Button
                  type='primary'
                  onClick={() => {
                    handleActionButton(record);
                    setType('pos');
                  }}
                  icon={<EditOutlined />}
                >
                  Пос тохиргоо
                </Button>
              )}
            </div>
          );
        },
      },
    ]);
  };
  return (
    <StyledTable
      pagination={false}
      bordered={false}
      columns={columns}
      dataSource={dataSource}
      scroll={{ x: 'max-content' }}
    />
  );
};
const StyledTable = styled(AntTable)`
  thead tr {
    font-size: 12px;
  }
  tbody tr {
    font-size: 12px;
    button {
      font-size: 12px;
    }
  }
  .success {
    color: green;
  }
  .danger {
    color: red;
  }
  .actions {
    display: flex;
    gap: 10px;
    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    button {
      display:flex;
      align-items:center;
    }
  }
`;
export default Table;
