import { useState } from 'react';
import { Layout } from 'antd';
const { Sider } = Layout;

const Sidebar = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      {children}
    </Sider>
  );
};
export default Sidebar;
