import { Button, Popconfirm, Table } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const AddressTable = ({
  columns: prevColumns,
  handleActionButton,
  dataSource,
  handleSelectedRows,
  deleteHandle
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [columns, setColmns] = useState(prevColumns);
  useEffect(() => {
    handleColumns();
  }, []);
  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    handleSelectedRows(selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const confirm = (record) => {
    deleteHandle(record);
  };
  const handleColumns = () => {
    setColmns([
      ...prevColumns,
      {
        title: '',
        dataIndex: 'actions',
        fixed: 'right',
        render: (_, record) => {
          return (
            <ButtonContainer>

            <Button
              type='primary'
              onClick={() => handleActionButton(record)}
              icon={<EditOutlined />}
            >
              Засах
            </Button>
                  <Popconfirm
                  title='Устгах'
                  description='Устгахдаа итгэлтэй байна уу?'
                  onConfirm={() => confirm(record)}
                  onCancel={() => {}}
                  okText='Yes'
                  cancelText='No'
                >
                  <Button danger type='primary' icon={<DeleteOutlined />}>
                    Устгах
                  </Button>
                </Popconfirm>
            </ButtonContainer>

          );
        },
      },
    ]);
  };
  return (
    <Container>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </Container>
  );
};
const Container = styled.div`
  margin-top: 20px;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;
export default AddressTable;
