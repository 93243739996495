import { InputNumber, Form } from 'antd';
import { useEffect } from 'react';
const InputNum = ({ name, required, form, ...props }) => {
  useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);
  return (
    <Form.Item
      width='100'
      label={props.label}
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <InputNumber min={0} {...props} />
    </Form.Item>
  );
};
export default InputNum;
