import axios from "utils/axios";
// import SelectHoc from 'contexts/SelectHoc';
import { Form, Select } from "antd";
import React from "react";
import configFile from "config.json";
import { NotificationManager } from "react-notifications";

const SelectCity = ({ name, form, required, ...props }) => {
  React.useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);
  const options = [
    { value: "УБ", label: "УБ" },
    { value: "Дархан", label: "Дархан" },
  ];
  const changeCity = () => {
    form.setFieldsValue({
      district: "",
    });
  };
  return (
    <Form.Item
      {...props.formItemLayout}
      label="Хот"
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <Select
        onChange={changeCity}
        placeholder="Хот сонгоно уу"
        allowClear
        options={options}
        style={{ minWidth: "100px", ...props?.style }}
      />
    </Form.Item>
  );
};

export default SelectCity;
