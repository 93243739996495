import * as React from 'react';
import { Form, Switch as AntSwitch } from 'antd';

const Switch = ({ name, form, required, ...props }) => {
  React.useEffect(() => {
    form.setFieldsValue({
      [name]:
        props.value === null || props.value === undefined ? false : props.value,
    });
  }, []);
  return (
    <Form.Item
      label={props.label}
      valuePropName='checked'
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
      {...props}
    >
      <AntSwitch />
    </Form.Item>
  );
};
export default Switch;
