import React, { useEffect } from "react";
import { Form, Input } from "antd";

const Text = ({ name, form, required, label, ...props }) => {
  useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);
  return (
    <Form.Item
      name={name}
      rules={[{ required: required, message: `${name} оруулна уу !` }]}
    >
      <Input {...props} />
    </Form.Item>
  );
};

export default Text;
