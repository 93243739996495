import React, { useEffect, useState } from 'react';
import { Form, TimePicker } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const format = 'HH:mm';
const TimePick = ({ name, form, value, required, ...props }) => {
  //   const [startDate, setStartDate] = useState(new Date());
  useEffect(() => {
    form.setFieldsValue({
      [name]: value !== null ? dayjs(value, format) : value,
    });
  }, []);

  return (
    <Form.Item
      {...props}
      label={props.label}
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      <TimePicker format={format} />
    </Form.Item>
  );
};

export default TimePick;
