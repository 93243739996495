import { Form } from 'antd';
import * as InputTypes from 'components/Form/InputTypes';
import React, { useEffect } from 'react';

const ScheduleFormDay = ({ model, day, forms }) => {
  const [form] = Form.useForm();
  const ref = React.createRef();
  const haveRedirect = Form.useWatch('haveRedirect', form);
  useEffect(() => {
    forms((prev) => {
      return [...prev, { day: day.weekDay, id: day.id, form: form }];
    });
    return () => {
      forms([]);
    };
  }, []);
  return (
    <>
      {Object.keys(model).map((field) => {
        const object = model[field];
        const InputType = InputTypes[object?.type];
        return (
          <Form form={form} key={field} ref={ref} layout='vertical'>
            {field === 'redirectStoreName' ||
            field === 'redirectStartTime' ||
            field === 'redirectEndTime' ||
            field === 'redirectEndTimeShift' ? (
              <InputType
                name={field}
                required={object.required}
                label={object.label}
                readOnly={object.readOnly}
                form={form}
                value={day[field]}
                style={{ display: haveRedirect ? 'block' : 'none' }}
              />
            ) : (
              <InputType
                name={field}
                required={object.required}
                label={object.label}
                readOnly={object.readOnly}
                form={form}
                value={day[field]}
              />
            )}
          </Form>
        );
      })}
    </>
  );
};
export default ScheduleFormDay;
