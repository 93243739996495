import { Form, Modal as AntModal, Upload, message } from 'antd';
import * as InputTypes from 'components/Form/InputTypes';
import { useState } from 'react';
import styled from 'styled-components';
const Modal = ({
  saveButtonText,
  cancelButtonText,
  isShowModal,
  setIsShowModal,
  handleSave,
  title,
  instance,
}) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const handleValidate = async () => {
    try {
      let values = await form.validateFields();
      console.log(values);
      handleSave(values);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = async () => {
    setIsShowModal(false);
  };
  return (
    <AntModal
      // width={1200}
      onOk={handleValidate}
      onCancel={handleCancel}
      open={isShowModal}
      okText={saveButtonText}
      cancelText={cancelButtonText}
      okType='primary'
    >
      <Container>
        <div className='title'>{title}</div>
        <Form form={form} {...layout}>
          <InputTypes.Input
            name={'name'}
            form={form}
            label={'MasterName'}
            required={true}
            readOnly={false}
            // value={instance["name"]}
            {...(instance ? { value: instance['name'] } : {})}
          />
          <InputTypes.UrlWithImage
            name={'url'}
            form={form}
            label={'Url оруулна уу'}
            required={false}
            readOnly={false}
            // value={instance["url"]}
            {...(instance ? { value: instance['url'] } : {})}
            style={{ marginBottom: 0 }}
          />
          <div className='or'>Эсвэл</div>
          <InputTypes.UploadImage
            name={'files'}
            form={form}
            label={''}
            required={false}
            readOnly={false}
          />
        </Form>
      </Container>
    </AntModal>
  );
};
const Container = styled.div`
  .title {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    display: flex;
  }
  .or {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    padding: 10px;
  }
`;
export default Modal;
