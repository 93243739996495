import { Button, Table as AntTable } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';

const Table = ({ columns, dataSource }) => {
  useEffect(() => {
    // handleColumns();
  }, []);
  //   const [columns, setColmns] = useState(prevColumns);

  //   const handleColumns = () => {
  //     setColmns([
  //       ...prevColumns,
  //       {
  //         title: '',
  //         dataIndex: 'actions',
  //         render: (_, record) => {
  //           return { _ };
  //         },
  //       },
  //     ]);
  //   };
  return (
    <AntTable
      pagination={false}
      columns={columns}
      dataSource={dataSource}
      scroll={{
        y: '80vh',
        x: '100%',
      }}
    />
  );
};
// const StyledTable = styled(AntTable)`
//   thead tr {
//     font-size: 12px;
//   }
//   tbody tr {
//     font-size: 12px;
//     button {
//       font-size: 12px;
//     }
//   }
//   .success {
//     color: green;
//   }
//   .danger {
//     color: red;
//   }
//   .actions {
//     width: 50px;
//     display: flex;
//     gap: 10px;
//     svg {
//       width: 20px;
//       height: 20px;
//       cursor: pointer;
//     }
//   }
// `;
export default Table;
