import { Button, Form, Table } from 'antd';
import axios from 'utils/axios';
import { SelectStore } from 'components/Form/InputTypes';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { tableSchedule as columns } from 'utils/tableModels';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import configFile from 'config.json';
import { formEditSchedule } from 'utils/formModels';
import Modal from 'components/Modal/ScheduleModal';
const Schedule = () => {
  const [form] = Form.useForm();
  const [store, setStore] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState('');
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    if (store) fetchData();
  }, [store]);

  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        `${configFile.PROD_SERVER_URL}/store/schedule/findByName`,
        {
          storeName: store,
        }
      );
      const mappedData = data.data.map((item, index) => {
        return { ...item, key: index + 1 };
      });
      setInstance(mappedData);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        'Error',
        5000
      );
      console.log(error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleStore = (item) => {
    setStore(item);
  };
  const handleEditSchedule = () => {
    setType('scheduleEdit');
    showModal();
  };
  const onChange = (e) => {};
  const handleSave = async (data) => {
    try {
      await Promise.all(
        data.map(async (item) => {
          await axios.post(
            `${configFile.PROD_SERVER_URL}/store/schedule/update`,
            // `http://192.168.230.115:5001/store/schedule/update`,
            {
              ...item,
            }
          );
        })
      );
      await fetchData();
      NotificationManager.success('Амжилттай хадгаллаа');
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, 'Error');
    }
    setIsModalOpen(false);
  };
  return (
    <Container>
      <Form form={form}>
        <div className='head'>
          <SelectStore form={form} name='store' onChange={handleStore} />
          <Button
            type='primary'
            icon={<EditOutlined />}
            disabled={store === null ? true : false}
            onClick={handleEditSchedule}
          >
            Засах
          </Button>
        </div>
      </Form>

      <Table
        columns={columns}
        dataSource={instance}
        onChange={onChange}
        scroll={{ x: 'max-content' }}
      />
      {isModalOpen && type === 'scheduleEdit' && (
        <Modal
          width={1050}
          isShowModal={isModalOpen}
          setIsShowModal={setIsModalOpen}
          handleSave={handleSave}
          saveButtonText='Хадгалах'
          cancelButtonText='Буцах'
          okType='primary'
          model={formEditSchedule}
          instance={instance}
          title={`"${store}" салбарын цагийн хуваарь засах`}
        />
      )}
    </Container>
  );
};
const Container = styled.div`
 background-color: #fff;
  .head {
    display: flex;
    gap 20px;
  }
`;
export default Schedule;
