import { Form, Tabs } from 'antd';
import { SelectStore } from 'components/Form/InputTypes';
import LogTable from 'components/Table/LogTable';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'utils/axios';
import configFile from 'config';
import { dateFormat } from 'utils/format';

const Logs = () => {
  const [data, setData] = useState([]);
  const [oldData, setOldData] = useState([]);
  // const [newData, setNewData] = useState([]);
  const [store, setStore] = useState(null);
  const [oldColumns, setOldColumns] = useState([]);
  // const [newColumns, setNewColumns] = useState([]);
  // const [tabs, setTabs] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    setData(null);
    setOldColumns([]);
    setOldData([]);
    if (store) fetchData();
  }, [store]);

  const fetchData = async () => {
    const {
      data: { data: logs },
    } = await axios.post(
      `${configFile.PROD_SERVER_URL}/menu/getHistoryLogs`,
      {
        storeName: store,
      }
    );
    setData(logs);
    onChangeTab(Object.keys(logs)[0], logs);
  };

  const handleStore = (value) => {
    setStore(value);
  };
  const onChangeTab = (tab, dataa) => {
    const item = dataa[tab][0];
    const cols = item.new ? item.new : item.old;
    if (cols) {
      const columns = Object.keys(cols).map((field) => {
        return {
          title: field,
          width: field === 'menu_level_entries' ? 700 : 200,
          dataIndex: field,
          key: field,
          render: (_) => {
           return field === 'created_at' || field === 'updated_at' ? dateFormat(_) : _;
          },
        };
      });
      setOldColumns(columns);
      let obj = {};
      columns.forEach((item) => {
        obj[item.dataIndex] = '';
      });
      const datas = dataa[tab].map((item, index) => {
        const oldData = item.old ? item.old : obj;
        const newData = item.new ? item.new : obj;
        return {
          ...newData,
          children: [{ key: `${index * 10 + 1}a`, ...oldData }],
          key: index + 1,
        };
      });
      setOldData(datas);
    }
  };
  return (
    <Form form={form}>
      <Container>
        <div className='store'>
          <SelectStore
            style={{ minWidth: '300px' }}
            form={form}
            name='store'
            onChange={handleStore}
          />
        </div>
        <div className='tables'>
          {data && data.length !== 0 && (
            <Tabs
              type='card'
              items={Object.keys(data).map((field, i) => {
                return {
                  label: field,
                  key: field,
                };
              })}
              onChange={(value) => {
                onChangeTab(value, data);
              }}
            />
          )}
          <LogTable dataSource={oldData} columns={oldColumns} />
        </div>
      </Container>
    </Form>
  );
};
const Container = styled.div`
  background: #fff;
  .store {
    display: flex;
  }
  .tables {
    width: 100%;
    // display: flex;
  }
`;
export default Logs;
