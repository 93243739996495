import React, { useState, useEffect } from 'react';
import Loader from './Loader';
import axiosInstance from 'utils/axios';
import { NotificationManager } from 'react-notifications';
const withLoader = (WrappedComponent) => {
  return (props) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const requestInterceptor = axiosInstance.interceptors.request.use(
        (config) => {
          setLoading(true);
          return config;
        }
      );

      const responseInterceptor = axiosInstance.interceptors.response.use(
        (response) => {
          setLoading(false);
          return response;
        },
        (error) => {
          setLoading(false);
          throw error;
        }
      );

      return () => {
        axiosInstance.interceptors.request.eject(requestInterceptor);
        axiosInstance.interceptors.response.eject(responseInterceptor);
      };
    }, []);
    return (
      <React.Fragment>
        {loading && <Loader />}
        <WrappedComponent {...props} />
      </React.Fragment>
    );
  };
};

export default withLoader;
