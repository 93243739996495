import { Button, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { getColumnSearchProps } from 'utils/table';

const UserTable = ({
  users: firstUsers,
  columns: firstColumns,
  handleActionButton,
}) => {
  const [columns, setColumns] = useState(firstColumns);
  const [data, setData] = useState([]);
  const searchButtonRef = useRef(null);
  const resetButtonRef = useRef(null);
  const searchInput = useRef(null);
  const actionColumns = [
    {
      title: '',
      dataIndex: 'actions',
      fixed: 'right',
      render: (_, record) => {
        return (
          <Button
            type='primary'
            onClick={() => {
              handleActionButton(record);
            }}
            icon={<EditOutlined />}
          >
            Засах
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    setData(indexedData(firstUsers));
  }, [firstUsers]);

  useEffect(() => {
    handleColumns();
  }, [data]);

  const handleColumns = () => {
    const columnWithFilters = firstColumns.map((column) => {
      return {
        ...column,
        ...getColumnSearchProps(
          column.dataIndex,
          searchInput,
          searchButtonRef,
          resetButtonRef
        ),
      };
    });
    setColumns([...columnWithFilters, ...actionColumns]);
  };

  const indexedData = (data) => {
    return data.map((item, index) => {
      return { ...item, key: index + 1 };
    });
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ x: 'max-content' }}
    />
  );
};
export default UserTable;
