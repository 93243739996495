import { useEffect, useState } from "react";
import styled from "styled-components";
import * as InputTypes from "./Form/InputTypes";
import { Form } from "antd";

const EditStore = ({
  instance: firstInstance,
  listenChange,
  type = "edit",
  model,
}) => {
  const [form] = Form.useForm();
  const [instance, setInstance] = useState(firstInstance);
  console.log(instance);
  useEffect(() => {
    listenChange && listenChange(instance);
  }, [instance]);

  const title =
    type === "edit"
      ? `${instance.storeName} салбарын мэдээлэл засах`
      : instance.storeName;

  return (
    <Container>
      <Form form={form}>
        <div className="title_header">
          <h2>{title}</h2>
        </div>
        {model.map((element, index) => {
          return (
            <div key={index} className="section">
              <div className="title_box">
                <p>{element.title}</p>
              </div>
              <div className="children">
                {Object.keys(element.children).map((field) => {
                  const object = element.children[field];
                  const InputType = InputTypes[object.type];
                  return (
                    <div
                      key={field}
                      className={
                        object.type === "Switch" ? "row_field" : "field"
                      }
                    >
                      <label>{object.label}</label>
                      <InputType
                        name={object.label}
                        required={object.required}
                        readOnly={object.readOnly}
                        value={instance[field]}
                        form={form}
                        onChangeClean={(value) =>
                          setInstance({ ...instance, [field]: value })
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Form>
    </Container>
  );
};
const Container = styled.div`
  .section {
    margin: 10px 0;
    .title_box {
      font-size: 16px;
      font-weight: 700;
    }
    .children {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin: 15px 0px;
    }
    .row_field {
      display: flex;
      gap: 10px;
    }
    .field {
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: flex-start;

      label {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
`;

export default EditStore;
