export const formEditStore = {
  storeName: { label: 'Салбар', type: 'Input', readOnly: true },
  haveAdvanced: { label: 'Урьдчилж захиалах', type: 'Switch' },
  haveDelivery: { label: 'Хүргэлт', type: 'Switch' },
  havePickup: { label: 'Очиж авах', type: 'Switch' },
  isActive: { label: 'Төлөв', type: 'Switch' },
  minDeliveryAmount: {
    label: 'Хүргэлт хийх захиалгын хамгийн бага дүн',
    type: 'InputNumber',
    required: true,
  },
  minPickupAmount: {
    label: 'Очиж авах захиалгын хамгийн бага дүн',
    type: 'InputNumber',
    required: true,
  },
  freeDelivery: {
    label: 'Үнэгүй хүргэлт хийгдэх',
    type: 'InputNumber',
    required: true,
  },
  quickMessage: { label: 'Туслах мессеж', type: 'Input', required: true },
  address: { label: 'Хаяг', type: 'Input' },
};
export const formEditPos = {
  hrsUrl: { label: 'hrsUrl', type: 'Input' },
  deliveryChgObjNum: {
    label: 'deliveryChgObjNum',
    type: 'InputNumber',
    required: true,
  },
  deliveryChgMasterName: {
    label: 'deliveryChgMasterName',
    type: 'Input',
    required: true,
  },
  storeNo: { label: 'storeNo', type: 'InputNumber', required: true },
  // companyItemMasterObjNum: {
  //   label: 'companyItemMasterObjNum',
  //   type: 'InputNumber',
  //   required: true,
  // },
  // deliveryItemMasterName: {
  //   label: 'deliveryItemMasterName',
  //   type: 'Input',
  //   required: true,
  // },
  // deliveryItemMasterObjNum: {
  //   label: 'deliveryItemMasterObjNum',
  //   type: 'InputNumber',
  //   required: true,
  // },
  // pickupItemMasterName: {
  //   label: 'pickupItemMasterName',
  //   type: 'Input',
  //   required: true,
  // },
  // pickupItemMasterObjNum: {
  //   label: 'pickupItemMasterObjNum',
  //   type: 'InputNumber',
  //   required: true,
  // },
};
export const formEditSchedule = {
  startTime: {
    type: 'SelectTime',
    required: true,
    label: 'Эхлэх цаг',
  },
  endTime: {
    type: 'SelectTime',
    required: true,
    label: 'Дуусах цаг',
  },
  endTimeShift: {
    type: 'Switch',
    label: 'Дараа өдөртөө орох',
  },
  haveRedirect: {
    type: 'Switch',
    label: 'Шилжүүлэх эсэх',
  },
  redirectStoreName: {
    type: 'SelectStore',
    label: 'Шилжүүлэх салбар',
  },
  redirectStartTime: {
    type: 'SelectTime',
    label: 'Шилжүүлэг эхлэх цаг',
  },
  redirectEndTime: {
    type: 'SelectTime',
    label: 'Шилжүүлэг дуусах цаг',
  },
  redirectEndTimeShift: {
    type: 'Switch',
    label: 'Шилжүүлэг дараа өдөртөө орох',
  },
};
export const formEditPod = {
  podCode: {
    type: 'Input',
    readOnly: true,
    label: 'Под код',
  },
  storeName: {
    type: 'SelectStore',
    required: true,
  },

  isActive: {
    type: 'Switch',
    label: 'Төлөв',
    required: true,
  },
};
export const formEditAddress = {
  city: {
    type: 'SelectCity',
    label: 'Хот',
    required: true,
  },
  district: {
    type: 'SelectDistrict',
    label: 'Дүүрэг',
    required: true,
  },
  horoo: {
    type: 'SelectHoroo',
    label: 'Хороо',
    required: true,
  },
  bairname: {
    type: 'Input',
    label: 'Хаяг',
    required: true,
  },
  bairnote: {
    type: 'Input',
    label: 'Албан бус хаяг:',
  },
  podph: {
    type: 'SelectPods',
    label: 'Х/Бүс',
  },
  latitude: {
    type: 'Input',
    label: 'Өргөрөг',
  },
  longitude: {
    type: 'Input',
    label: 'Уртраг',
  },
};
export const formCreateAddress = {
  city: {
    type: 'SelectCity',
    label: 'Хот',
    required: true,
  },
  district: {
    type: 'SelectDistrict',
    label: 'Дүүрэг',
    required: true,
  },
  horoo: {
    type: 'SelectHoroo',
    label: 'Хороо',
    required: true,
  },
  bairname: {
    type: 'Input',
    label: 'Хаяг',
    required: true,
  },
  bairnote: {
    type: 'Input',
    label: 'Албан бус хаяг:',
  },
  podph: {
    type: 'SelectPods',
    label: 'PH Х/Бүс',
    required: true,
  },
  latitude: {
    type: 'Input',
    label: 'Өргөрөг',
    required: true,
  },
  longitude: {
    type: 'Input',
    label: 'Уртраг',
    required: true,
  },
};
export const formEditMultiple = {
  city: {
    type: 'SelectCity',
    label: 'Хот',
  },
  district: {
    type: 'SelectDistrict',
    label: 'Дүүрэг',
  },
  horoo: {
    type: 'SelectHoroo',
    label: 'Хороо',
  },
  podph: {
    type: 'SelectPods',
    label: 'PH Х/Бүс',
  },
};
export const formTranslation = {
  en: {
    type: 'Input',
    label: 'Англи',
  },
  mn: {
    type: 'Input',
    label: 'Монгол',
  },
};

export const formEditCombo = {
  optionName: {
    type: 'Input',
    label: 'Name',
  },
  type: {
    label: 'Type',
    type: 'SelectTypeCombo',
    required: true,
  },
  items: {
    type: 'ComboTable',
    required: true,
    label: 'Бүтээгдэхүүн',
  },
};
export const formSingleTypeCombo = [
  'Beverage',
  'Deal',
  'Dessert',
  'Meal',
  'Others',
  'Side',
];
export const formImage = {
  name: {
    type: 'Input',
    label: 'Мастер',
    required: true,
  },

  files: {
    type: 'UploadImage',
    label: 'Зураг оруулах',
  },

  url: {
    type: 'Input',
    label: 'URL оруулна уу',
  },
};
