import React, { useEffect } from 'react';
import styledComponents from 'styled-components';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { getAuthContext } from 'contexts/auth';
import { getReferenceContext } from 'contexts/reference';
import LoginComponent from 'components/Login';
import ForceUpdatePass from 'components/ForceUpdatePass';

const Login = () => {
  const { loggedIn, setLoggedIn } = React.useContext(getAuthContext);
  const { setIsLoading } = React.useContext(getReferenceContext);
  const [type, setType] = React.useState('login');
  const [email, setEmail] = React.useState('');
  const [user, setUser] = React.useState('');

  const navigate = useNavigate();
  if (loggedIn) navigate('/');
  const handleSubmit = async (data) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      NotificationManager.warning(
        `хэрэглэгч аль хэдийн нэвтэрсэн байна`,
        `${user.username}`,
        5000,
      );
      navigate('/');
    } catch (error) {
      setIsLoading(true);
      setEmail(data.email);
      try {
        const loginUser = await Auth.signIn(data.email, data.password);
        setUser(loginUser);
        if (loginUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          setType('newPass');
          setIsLoading(false);
        } else {
          setLoggedIn(true);
          setIsLoading(false);
          navigate('/');
          // other situations
        }
      } catch (e) {
        setIsLoading(false);
        switch (e.name) {
          case 'UserNotConfirmedException':
            try {
              await Auth.resendSignUp(email);
              console.log('code resent successfully');
              navigate('/confirm');
            } catch (err) {
              NotificationManager.error(
                err.response.data.errorMessage,
                'Error',
                5000
              );
              console.log('error resending code: ', err);
            }
          default:
            break;
        }
        console.log(e.message);
        NotificationManager.error(e.message, 'Error');
      }
    }
  };
  return type === 'login' ? (
    <LoginComponent handleSubmit={handleSubmit} />
  ) : type === 'newPass' ? (
    <ForceUpdatePass email={email} user={user} setLoggedIn={setLoggedIn} />
  ) : (
    <></>
  );
};

export default Login;
