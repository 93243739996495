import { Button } from "antd";
import { useEffect, useState } from "react";
import { tableTranslate as columns } from "utils/tableModels";
import axios from "utils/axios";
import configFile from "config.json";
import { NotificationManager } from "react-notifications";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { formTranslation } from "utils/formModels";
import Modal from "components/Modal/TranslateModal";
import Table from "components/Table/TranslateTable";

const Translate = () => {
  const [data, setData] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [instance, setInstance] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        `${configFile.PROD_SERVER_URL}/menu/getTranslations`
      );
      const mappedData = data.data.map((item, index) => {
        return { ...item, key: index + 1 };
      });
      setData(mappedData);
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, "Error", 5000);
      console.log(error);
    }
  };
  const showModal = () => {
    setIsShowModal(true);
  };

  const handleSaveData = async (data) => {
    try {
      if (instance) {
        await axios.post(
          `${configFile.PROD_SERVER_URL}/menu/updateTranslation`,
          {
            ...data,
          }
        );
        NotificationManager.success("Амжилттай шинэчиллээ");
      } else {
        await axios.post(
          `${configFile.PROD_SERVER_URL}/menu/createTranslation`,
          {
            ...data,
          }
        );

        NotificationManager.success("Амжилттай хадгаллаа");
      }
      setIsShowModal(false);
      fetchData();
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, "Error", 5000);
      console.log(error);
    }
  };
  const handleCreate = async () => {
    setInstance(null);
    setType("create");
    showModal();
  };

  const deleteHandle = async (instance) => {
    try {
      console.log("delete", instance);
      await axios.post(`${configFile.PROD_SERVER_URL}/menu/deleteTranslation`, {
        en: instance.en,
      });
      NotificationManager.success("Амжилттай устгалаа");
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage);
    }
    fetchData();
  };
  const handleInstance = (instance) => {
    setInstance(instance);
    showModal();
  };
  return (
    <Container>
      <Button type="primary" onClick={handleCreate} icon={<PlusOutlined />}>
        Шинээр үүсгэх
      </Button>

      <StyledTable
        handleActionButton={handleInstance}
        columns={columns}
        dataSource={data}
        setType={setType}
        deleteHandle={deleteHandle}
        type={type}
      />

      {isShowModal && (
        <Modal
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          saveButtonText="Хадгалах"
          cancelButtonText="Буцах"
          model={formTranslation}
          title="Шинээр үүсгэх"
          handleSave={handleSaveData}
          type={type}
          instance={instance}
        />
      )}
    </Container>
  );
};
const Container = styled.div``;
const StyledTable = styled(Table)`
  margin-top: 20px;
`;
export default Translate;
