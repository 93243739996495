import SelectDistrict from "components/Form/InputTypes/SelectDistrict";
import SelectHoroo from "components/Form/InputTypes/SelectHoroo";
import SelectPods from "components/Form/InputTypes/SelectPods";
import SearchInput from "components/Form/InputTypes/SearchInput";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import axios from "utils/axios";
import configFile from "config.json";
import { tableAddress as columns } from "utils/tableModels";
import Table from "components/Table/AddressTable";
import { PlusOutlined } from "@ant-design/icons";
import Modal from "components/Modal/Address/Modal";
import {
  formCreateAddress,
  formEditAddress,
  formEditMultiple,
} from "utils/formModels";
import { Form } from "antd";
import { NotificationManager } from "react-notifications";
import Loader from "components/Loader";
import { SelectCity } from "components/Form/InputTypes";

const Address = () => {
  const [formSearch] = Form.useForm();
  const [data, setData] = useState([]);
  const [pageInterval, setPageInterval] = useState(-1);
  const [pageLimit] = useState(10);
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [formModel, setFormModel] = useState({});
  const [instance, setInstance] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const searchButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const district = Form.useWatch("district", formSearch);
  const city = Form.useWatch("city", formSearch);

  useEffect(() => {
    handleSearch();
  }, [pageInterval]);

  useEffect(() => {
    setPageInterval(0);
  }, [district]);

  const handleSearch = async () => {
    try {
      let values = await formSearch.validateFields();
      Object.keys(values).map((field) =>
        values[field] ? values[field] : (values[field] = "")
      );
      const {
        data: { data },
      } = await axios.get(
        `${configFile.SERVER_URL_ADDRESS}/findAll?city=${values.city}&district=${values.district}&horoo=${values.horoo}&podph=${values.pod}&limit=${pageLimit}&address=${values.address}&from=${pageInterval}`
      );
      const mappedData = data.map((item, index) => {
        return { ...item, key: index + 1 };
      });
      setData(mappedData);
    } catch (errorInfo) {
      NotificationManager.error(errorInfo.response.data.errorMessage, 5000);
    }
  };

  const showModal = () => {
    setIsShowModal(true);
  };

  const handleTableAction = (instance) => {
    setFormModel(formEditAddress);
    setModalType("editAddress");
    setInstance(instance);
    showModal();
  };
  const handleCreateAddress = () => {
    setFormModel(formCreateAddress);
    setModalType("createAddress");
    setInstance(null);
    showModal();
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleMultipleEdit = () => {
    setFormModel(formEditMultiple);
    setModalType("multipleEdit");
    setInstance(selectedRowKeys);
    showModal();
  };
  const handleSaveData = async (values, instance, type) => {
    try {
      switch (type) {
        case "createAddress":
          // setLoading(true);
          formSearch.setFieldsValue({
            district: values.district,
            horoo: values.horoo,
            pod: values.podph,
            address: values.bairname,
          });

          await axios.post(
            configFile.SERVER_URL_ADDRESS + "/upsertByAddress",
            values
          );
          setIsShowModal(false);
          setTimeout(() => {
            NotificationManager.success("Амжилттай үүсгэлээ");
            // setLoading(false);

            handleSearch();
          }, 1000);

          break;
        case "editAddress":
          // setLoading(true);
          await axios.post(configFile.SERVER_URL_ADDRESS + "/upsertById", {
            _id: instance._id,
            ...values,
          });
          setIsShowModal(false);
          setTimeout(() => {
            NotificationManager.success("Амжилттай шинэчиллээ");
            // setLoading(false);
            handleSearch();
          }, 1000);

          break;
        case "multipleEdit":
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.response.data.message, "Error");
    }
  };
  const deleteHandle = async (instance) => {
    try {
      await axios.post(`${configFile.SERVER_URL_ADDRESS}/deleteById`, {
        _id: instance._id,
      });
      NotificationManager.success("Амжилттай устгалаа");
      setTimeout(() => {
        handleSearch();
      }, 1000);
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage);
    }
  };
  const prevPagination = () => {
    setPageInterval((prev) => prev - pageLimit);
  };
  const nextPagination = async () => {
    setPageInterval((prev) => prev + pageLimit);
  };
  return (
    <Container>
      <Form form={formSearch} style={{ width: "100%" }}>
        <div className="head">
          <div className="field">
            <SelectCity form={formSearch} name={"city"} value="УБ" />
          </div>
          <div className="field">
            <SelectDistrict form={formSearch} city={city} name={"district"} />
          </div>
          <div className="field">
            <SelectHoroo
              form={formSearch}
              name={"horoo"}
              city={city}
              district={district}
            />
          </div>
          <div className="field">
            <SelectPods form={formSearch} name={"pod"} />
          </div>
          <div className="field">
            <SearchInput
              refSearch={searchButtonRef}
              form={formSearch}
              name={"address"}
              enterButton={true}
              handleSearch={() => {
                handleSearch();
              }}
            />
          </div>
          <div className="field">
            <Button
              type="primary"
              onClick={handleCreateAddress}
              icon={<PlusOutlined />}
            >
              Хаяг нэмэх
            </Button>
          </div>
        </div>
        <div className="body-container">
          <div className="editMultiple">
            <Button
              type="primary"
              onClick={handleMultipleEdit}
              // disabled={!hasSelected}
              disabled={true}
            >
              Edit
            </Button>
            <span
              style={{
                marginLeft: 8,
              }}
            >
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
            </span>
          </div>
          <Table
            // style={{ width: 920 }}
            handleActionButton={handleTableAction}
            columns={columns}
            dataSource={data}
            handleSelectedRows={setSelectedRowKeys}
            deleteHandle={deleteHandle}
          />
          <div className="pagination">
            <Button
              type="primary"
              // disabled
              disabled={pageInterval === 0 ? true : false}
              onClick={prevPagination}
              size="small"
            >
              Өмнөх
            </Button>
            <Button
              type="primary"
              onClick={nextPagination}
              disabled={data.length === 0 ? true : false}
              size="small"
            >
              Дараагийх
            </Button>
          </div>
        </div>
      </Form>
      {isShowModal && (
        <Modal
          width={1050}
          type={modalType}
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          saveButtonText="Хадгалах"
          cancelButtonText="Буцах"
          model={formModel}
          instance={instance}
          handleSaveData={handleSaveData}
          handleSearch={handleSearch}
        />
      )}
      {loading && <Loader />}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  padding: 30px;
  background: #fff;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  .head {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .pagination {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: flex-end;
  }
`;
export default Address;
