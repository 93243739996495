import { useEffect, useState } from 'react';
import { Checkbox, Divider, Input } from 'antd';
import styled from 'styled-components';

const CheckboxGroup = Checkbox.Group;
const SelectGroups = ({ oldGroups, groups: firstGroups, setNewGroups }) => {
  const [allGroups, setAllGroups] = useState(firstGroups);
  const [groups, setGroups] = useState([]);
  const [storeGroups, setStoreGroups] = useState([]);
  const [checkedListGroups, setCheckedListGroups] = useState([]);
  const [checkedListStore, setCheckedListStore] = useState([]);

  // const [firstCheckedAllLists, setFirstCheckedAllLists] = useState([]);

  const [indeterminateGroups, setIndeterminateGroups] = useState(false);
  const [indeterminateStore, setIndeterminateStore] = useState(false);

  const [checkAllGroup, setCheckAllGroup] = useState(false);
  const [checkAllStore, setCheckAllStore] = useState(false);

  useEffect(() => {
    const data = splitGroups(allGroups);
    setGroups(data.groupData);
    setStoreGroups(data.storeGroupData);
  }, [allGroups]);

  useEffect(() => {
    const data = splitGroups(oldGroups);
    setCheckedListGroups(data.groupData);
    setCheckedListStore(data.storeGroupData);
  }, [oldGroups]);

  useEffect(() => {
    setNewGroups([...checkedListGroups, ...checkedListStore]);
  }, [checkedListGroups, checkedListStore]);

  const onChangeGroups = (list) => {
    setCheckedListGroups(list);
    setIndeterminateGroups(!!list.length && list.length < groups.length);
    setCheckAllGroup(list.length === groups.length);
  };
  const onChangeStore = (list) => {
    setCheckedListStore(list);
    setIndeterminateStore(!!list.length && list.length < storeGroups.length);
    setCheckAllStore(list.length === storeGroups.length);
  };
  const onCheckAllChangeGroup = (e) => {
    setCheckedListGroups(e.target.checked ? groups : []);
    setIndeterminateGroups(false);
    setCheckAllGroup(e.target.checked);
  };

  const onCheckAllChangeStore = (e) => {
    setCheckedListStore(e.target.checked ? storeGroups : []);
    setIndeterminateStore(false);
    setCheckAllStore(e.target.checked);
  };

  const onChangeSearch = (ev) => {
    const value = ev.target.value.toLowerCase();
    const searchedData = firstGroups.filter((item) => {
      const group = item.toLowerCase();
      if (group.includes(value)) {
        return item;
      }
    });
    setAllGroups(searchedData);
  };

  return (
    <Container>
      <Input.Search
        size='medium'
        placeholder='Group хайх'
        onChange={onChangeSearch}
        style={{ width: '100%' }}
      />
      <div className='box-container'>
        <div className='box'>
          <Checkbox
            indeterminate={indeterminateGroups}
            onChange={onCheckAllChangeGroup}
            checked={checkAllGroup}
          >
            Бүгдийг нь зөвшөөрөх
          </Checkbox>
          <div className='divider' />
          <StyledCheckboxGroup
            options={groups}
            value={checkedListGroups}
            onChange={onChangeGroups}
          />
        </div>
        <div className='box'>
          <Checkbox
            indeterminate={indeterminateStore}
            onChange={onCheckAllChangeStore}
            checked={checkAllStore}
          >
            Бүгдийг нь зөвшөөрөх
          </Checkbox>
          <div className='divider' />
          <StyledCheckboxGroup
            options={storeGroups}
            value={checkedListStore}
            onChange={onChangeStore}
          />
        </div>
      </div>
    </Container>
  );
};
const splitGroups = (data) => {
  let groupData = [];
  let storeGroupData = [];
  data.forEach((element) => {
    if (element.includes('STORE_')) {
      storeGroupData.push(element);
    } else {
      groupData.push(element);
    }
  });
  return { groupData, storeGroupData };
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .box-container {
    display: flex;
    gap: 20px;
  }
  .box {
    .divider {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin: 5px 0;
    }
  }
`;
const StyledCheckboxGroup = styled(CheckboxGroup)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    margin: 0 !important;
  }
`;
export default SelectGroups;
