import {
  Form,
  Input as InputAnt,
  Modal as AntModal,
  Table,
  Collapse,
  Popconfirm,
  Button,
  List,
} from "antd";
import RawItems from "components/Combo/RawItems";
import { Input, InputArea, Switch } from "components/Form/InputTypes";
import { useEffect, useState } from "react";
import styled from "styled-components";
import configFile from "config.json";
import axios from "utils/axios";
import { NotificationManager } from "react-notifications";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { CreateCombo } from ".";
import SelectCombo from "components/Form/InputTypes/SelectCombo";
import SelectComboItems from "components/Combo/SelectComboItems";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

const Modal = ({
  cancelButtonText,
  okButtonText,
  isShowModal,
  setIsShowModal,
  instance,
  rawMainItems: firstRawMainItems,
  rawSideItems: firstRawSideItems,
  type,
  title,
  store,
  menus,
  fetchData,
}) => {
  const { Panel } = Collapse;
  const [rawMainItems, setRawMainItems] = useState(firstRawMainItems);
  const [rawSideItems, setRawSideItems] = useState(
    firstRawSideItems ? firstRawSideItems : []
  );
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [condiments, setCondiments] = useState(menus.Condiments);
  const [form] = Form.useForm();

  useEffect(() => {
    setRawMainItems(instance?.rawMainItems);
    setRawSideItems(instance?.rawSideItems);
  }, [instance]);

  const validateOptionName = (rule, value) => {
    const foundItem = rawSideItems?.filter((item) => item.optionName === value);
    if (foundItem?.length > 0) {
      return Promise.reject("Давтагдсан нэр байна");
    }
    return Promise.resolve();
  };

  const handleCancel = async () => {
    setIsShowModal(false);
  };

  const changePosition = (index, val) => {
    const sideItems = [...rawSideItems];
    if (sideItems.length !== index + val && index + val >= 0) {
      [sideItems[index], sideItems[index + val]] = [
        sideItems[index + val],
        sideItems[index],
      ];
    }
    setRawSideItems(sideItems);
  };

  const handleAddSideItem = (values) => {
    rawSideItems
      ? setRawSideItems((prev) => [
          ...prev,
          {
            items: [],
            optionName: values.optionName,
            type: values.type,
          },
        ])
      : setRawSideItems([
          {
            items: [],
            optionName: values.optionName,
            type: values.type,
          },
        ]);
    setIsCreateModalOpen(false);
  };
  const handleDeleteSideItem = (item) => {
    const sideItems = rawSideItems.filter(
      (sideItem) => sideItem.optionName !== item.optionName
    );
    setRawSideItems(sideItems);
  };
  const extraItems = (item, index) => {
    return type === "show" ? (
      <></>
    ) : (
      <div className="extraItems">
        <Popconfirm
          title="Устгах"
          description="Устгахдаа итгэлтэй байна уу?"
          onConfirm={(ev) => handleDeleteSideItem(item)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button
            size="small"
            danger
            type="primary"
            icon={<DeleteOutlined />}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            Устгах
          </Button>
        </Popconfirm>
        <div className="arrows">
          <AiOutlineArrowUp
            onClick={(e) => {
              e.stopPropagation();
              changePosition(index, -1);
            }}
            className="arrowItemUp arrowItem"
            size={20}
          />
          <AiOutlineArrowDown
            onClick={(e) => {
              e.stopPropagation();
              changePosition(index, 1);
            }}
            className="arrowItemDown arrowItem"
            size={20}
          />
        </div>
      </div>
    );
  };
  const addSideItem = () => {
    return type !== "show" ? (
      <Button
        size="small"
        style={{ backgroundColor: "rgb(103, 194, 58)" }}
        type="primary"
        icon={<PlusOutlined />}
        onClick={(event) => {
          event.stopPropagation();
          setIsCreateModalOpen(true);
        }}
      >
        Нэмэх
      </Button>
    ) : null;
  };
  const handleRawMain = (item) => {
    setRawMainItems(item.data);
  };
  const handleRawSide = (item) => {
    const updatedItems = rawSideItems.map((sideItem, index) => {
      if (index === item.currentIndex) {
        return { ...item.data };
      }
      return sideItem;
    });
    setRawSideItems(updatedItems);
  };
  const handleSave = async () => {
    try {
      let values = await form.validateFields();

      const data = {
        storeName: store,
        comboMenuId: values.comboMenuId,
        longDescriptor_stringText: values.longDescriptor_stringText,
        primaryComboGroupId: values.primaryComboGroupId,
        masterName: values.masterName,
        rawMainItems: rawMainItems,
        rawSideItems: rawSideItems,
        active : values.active
      };
      if (type === "show") {
        handleCancel();
      } else {
        if (type === "create") {
          await axios.post(
            `${configFile.PROD_SERVER_URL}/menu/createOrUpdateComboMenu`,
            {
              ...data,
            }
          );
        } else if (type === "edit") {
          await axios.post(
            `${configFile.PROD_SERVER_URL}/menu/createOrUpdateComboMenu`,
            {
              ...data,
              id: instance ? instance.id : null,
            }
          );
        }
        NotificationManager.success("Амжилттай хадгаллаа");
        handleCancel();
        fetchData();
      }
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage, "Error");
    }
  };
  const setSideData = (prev, newData) => {
    const filtered = rawSideItems.filter(
      (item) => item.optionName !== prev.optionName
    );
    setRawSideItems([...filtered, newData]);
  };
  const setMainData = (prev, newData) => {
    setRawMainItems(newData);
  };
  const searchCond = (item) => {
    const value = item.target.value.toLowerCase();
    const searchedData = menus.Condiments.filter((item) => {
      const masterName = item.masterName.toLowerCase();
      const miMasterObjNum = item.miMasterObjNum.toLowerCase();
      if (masterName.includes(value) || miMasterObjNum.includes(value)) {
        return item;
      }
    });
    setCondiments(searchedData);
  };
  return (
    <AntModal
      width={"1600px"}
      onOk={handleSave}
      onCancel={handleCancel}
      open={isShowModal}
      cancelText={cancelButtonText}
      okText={okButtonText}
      okType="primary"
    >
      <Container>
        <div className="head">{title}</div>
        <div className="body-container">
          <Form form={form}>
            <div className="head-container">
              <Input
                required
                name={"masterName"}
                form={form}
                value={instance?.masterName}
                label="Нэр"
                readOnly={true}
                size="small"
                disabled={true}
              />
              <Input
                required
                name={"primaryComboGroupId"}
                form={form}
                value={instance?.primaryComboGroupId}
                label="primaryComboGroupId"
                readOnly={type === "show" ? true : false}
                size="small"
              />
              <SelectCombo
                data={menus.Combo}
                form={form}
                name={"comboMenuId"}
                label="Багц"
                value={instance?.comboMenuId}
                required
                disabled={type === "show" ? true : false}
                size="small"
              />
              <Switch
                name="active"
                form={form}
                label="Төлөв"
                value={instance?.active}
                required
              />
            </div>
            <div className="">
              <InputArea
                name={"longDescriptor_stringText"}
                form={form}
                value={instance?.longDescriptor_stringText}
                label="Тайлбар"
                readOnly={type === "show" ? true : false}
                size="small"
                // style={{display:"none"}}
              />
            </div>
          </Form>
          <div className="columns">
            <div className="leftColumn column">
              <InputAnt.Search
                size="small"
                placeholder="Бүтээгдэхүүн хайх"
                onChange={searchCond}
                enterButton
              />

              <ListStyle
                dataSource={condiments}
                renderItem={(item) => (
                  <List.Item key={item.masterName}>
                    <List.Item.Meta
                      title={`${item.masterName} (${item.miMasterObjNum})`}
                    />
                    <div className="rawitems">
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                          const cloned = { ...rawMainItems };
                          cloned.items = [...cloned?.items, item];
                          setRawMainItems(cloned);
                        }}
                        disabled={
                          rawMainItems?.items?.filter(
                            (i) =>
                              i.masterName === item.masterName &&
                              i.miMasterObjNum === item.miMasterObjNum
                          ).length > 0 || rawMainItems?.type !== item.type
                        }
                      >
                        {rawMainItems?.optionName}
                      </Button>
                      {rawSideItems?.map((ite, index) => {
                        return (
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                              const cloned = [...rawSideItems];
                              const clonedItems = [...cloned[index]?.items];
                              clonedItems.push(item);
                              cloned[index].items = clonedItems;
                              setRawSideItems(cloned);
                            }}
                            disabled={
                              ite.items.filter(
                                (i) =>
                                  i.masterName === item.masterName &&
                                  i.miMasterObjNum === item.miMasterObjNum
                              ).length > 0 || ite.type !== item.type
                            }
                          >
                            {ite.optionName}
                          </Button>
                        );
                      })}
                    </div>
                    {/* <div>Content</div> */}
                  </List.Item>
                )}
              />
              {/* {menus.Condiments.map((item) => {
                <div className=''>{item.masterName}</div>;
              })} */}
            </div>
            <div className="rightColumn column">
              <Collapse
                size="small"
                defaultActiveKey={["main"]}
                expandIconPosition="end"
              >
                <Panel header="Main items" key="main">
                  <RawItems
                    data={rawMainItems}
                    // menus={menus}
                    setRawItem={handleRawMain}
                    typeAction={type}
                    handleItem={setMainData}
                  />
                </Panel>
                <Panel header="Side items" key="2" extra={addSideItem()}>
                  <Collapse
                    size="small"
                    defaultActiveKey={["1"]}
                    expandIconPosition="end"
                  >
                    {rawSideItems?.map((item, index) => {
                      return (
                        <Panel
                          header={item.optionName}
                          key={index}
                          extra={extraItems(item, index)}
                        >
                          <RawItems
                            data={item}
                            // menus={menus}
                            setRawItem={handleRawSide}
                            // validateOptionName={validateOptionName}
                            handleItem={setSideData}
                            rawItems={rawSideItems}
                            // setRawItems={setRawSideItems}
                            typeAction={type}
                            index={index}
                          />
                        </Panel>
                      );
                    })}
                  </Collapse>
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
        {isCreateModalOpen && (
          <CreateCombo
            // width={"1200px"}
            isShowModal={isCreateModalOpen}
            cancelButtonText={"Хаах"}
            setIsShowModal={setIsCreateModalOpen}
            saveButtonText="Хадгалах"
            handleSave={handleAddSideItem}
            okType="primary"
            title="Шинээр үүсгэх"
            rawSideItems={rawSideItems}
            validateOptionName={validateOptionName}
          />
        )}
      </Container>
    </AntModal>
  );
};
const StyledTable = styled(Table)`
  width: 100%;
`;
const ListStyle = styled(List)`
  border: 1px solid rgba(140, 140, 140, 0.35);
  padding: 0 16px;
  margin-top: 10px;
  border-radius: 5px;
`;
const Container = styled.div`
  .extraItems {
    display: flex;
    gap: 20px;
    .arrows {
      display: flex;
      gap: 10px;
      .arrowItem {
        cursor: pointer;
      }
    }
  }
  .body-container {
    display: flex;
    flex-direction: column;
    .head-container {
      display: flex;
      width: 100%;
      gap: 20px;
    }
    .rawitems {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      flex: 1;
      justify-content: end;
    }
    .columns {
      width: 100%;

      .leftColumn {
      }
      .column {
        width: 50%;
        float: left;
        padding: 10px;
        height: 600px;
        overflow: auto;
      }
    }
  }
  .head {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    display: flex;
  }
  .label {
    font-weight: 500;
  }
`;
const indexedData = (array) => {
  return array?.map((item, index) => {
    return { ...item, key: index + 1 };
  });
};
export default Modal;
