import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getAuthContext } from 'contexts/auth';
import { Divider, List } from 'antd';
const Profile = () => {
  const { user, groups } = React.useContext(getAuthContext);
  return (
    <Container>
      <Divider orientation='left'>{user?.username}</Divider>
      <List
        size='small'
        header={<div style={{ fontWeight: 700 }}>Groups</div>}
        bordered
        dataSource={groups?.sort() ? groups : []}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    </Container>
  );
};
const Container = styled.div``;

export default Profile;
