import React, { useEffect, useState } from 'react';
import { Form, Input, Upload } from 'antd';

const UrlWithImage = ({
  name,
  form,
  required,
  customValidate,
  masterName,
  ...props
}) => {
  const [url, setUrl] = useState(props.value);
  const formUrl = Form.useWatch('url', form);
  const files = Form.useWatch('files', form);
  useEffect(() => {
    form.setFieldsValue({
      [name]: props.value === null ? '' : props.value,
    });
  }, []);
  useEffect(() => {
    setUrl(formUrl);
  }, [formUrl]);
  return (
    <>
      <Form.Item
        label={props.label}
        name={name}
        rules={[
          { required: required, message: `${props.label} оруулна уу !` },
          customValidate ? { validator: customValidate } : null,
        ]}
        {...props}
      >
        <Input {...props} />
      </Form.Item>
      <Upload
        listType='picture'
        onRemove={(e) => {
          form.setFieldsValue({ url: '' });
        }}
        maxCount={1}
        {...(url !== '' && url !== undefined
          ? {
              fileList: [
                {
                  name: url,
                  uid: '-1',
                  status: 'done',
                  url: url,
                },
              ],
            }
          : {})}
      />
    </>
  );
};

export default UrlWithImage;
