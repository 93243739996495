import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import { TfiLocationPin } from 'react-icons/tfi';
export const topMenuItems = [
  {
    label: 'Самбар',
    key: 'dashboard',
    icon: <SettingOutlined />,
  },
  {
    label: 'Салбар',
    key: 'settings',
    icon: <SettingOutlined />,
    children: [
      { label: 'Салбар', key: 'stores-settings' },
      { label: 'Хуваарь', key: 'schedule-settings' },
      { label: 'Под', key: 'pod-settings' },
      { label: 'Салбар шалгах', key: 'pos-check' },
    ],
  },
  {
    label: 'Хаяг',
    key: 'address',
    icon: <TfiLocationPin size={16} />,
    children: [
      { label: 'Хаяг засах', key: 'edit-address' },
      { label: 'Байршил засах', key: 'edit-location' },
      { label: 'Байршлаар хайх', key: 'search-location' },
    ],
  },
  {
    label: 'Цэсийн удирдлага',
    key: 'menus',
    icon: <SettingOutlined />,
    children: [
      { label: 'Бүх меню татах', key: 'getmenuAllStore' },
      { label: 'Бүтээгдэхүүн', key: 'list' },
      { label: 'Багц меню', key: 'combo' },
      { label: 'Багц', key: 'copyCombo' },
      { label: 'Орчуулга', key: 'translate' },
      { label: 'Лог', key: 'log' },
      { label: 'Зураг', key: 'images' },
    ],
  },
  {
    label: 'Хэрэглэгчийн тохиргоо',
    key: 'users',
    icon: <SettingOutlined />,
    // children: [
    //   { label: 'Бүтээгдэхүүн', key: 'list' },
    //   { label: 'Багц', key: 'combo' },
    //   { label: 'Орчуулга', key: 'translate' },
    // ],
  },
  {
    label: 'Profile',
    key: 'profile1',
    icon: <UserOutlined />,
    children: [
      { label: 'Мэдээлэл', key: 'profile' },
      { label: 'Гарах', key: 'logout' },
    ],
  },
];
export const bottomMenuItems = [
  {
    label: 'Profile',
    key: 'settings',
    icon: <UserOutlined />,
    children: [
      { label: 'Мэдээлэл', key: 'profile' },
      { label: 'Гарах', key: 'logout' },
    ],
  },
];
