import { Button, Table, Tag } from "antd";
// import Table from 'components/Table/StoreTable';
import configFile from "config.json";
import { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import axiosCustom from "utils/axios";
import axios from "axios";
// import { tablePosCheck as columns } from "utils/tableModels";
import { getAuthContext } from "contexts/auth";
import { format } from "date-fns";
const GetmenuAllStore = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { jwtToken } = useContext(getAuthContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await axiosCustom.post(
        `${configFile.PROD_SERVER_URL}/menu/getMenusAllStore`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const mappedData = data.data.map((item, index) => ({
        ...item,
        success: "",
        key: index + 1,
      }));
      setData(mappedData);
      setTableData(mappedData);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
      console.log(error);
    }
  };

  const getAllMenus = async () => {
    for (const store of data.filter((item, index) =>
      selectedRowKeys.includes(index + 1)
    )) {
      const interval = simulateProgressUpdate(store?.storeName);
      setTableData((prevItems) =>
        prevItems.map((item) =>
          item.storeName === store.storeName
            ? { ...item, success: "pending" }
            : item
        )
      );
      try {
        const { data: response } = await axios.post(
          `${configFile.PROD_SERVER_URL}/menu/updateMenusFromStore`,
          {
            storeName: store?.storeName,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        setTableData((prevItems) =>
          prevItems.map((item) =>
            item.storeName === store.storeName
              ? { ...item, ...response?.data, success: true }
              : item
          )
        );
      } catch (error) {
        setTableData((prevItems) =>
          prevItems.map((item) =>
            item.storeName === store.storeName
              ? { ...item, success: false }
              : item
          )
        );
      } finally {
        clearInterval(interval);
      }
    }
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <Container>
      <div className="head">
        <Button
          onClick={getAllMenus}
          disabled={!hasSelected}
          className="check-button"
        >
          Меню татах
        </Button>

        <Table
          // handleActionButton={handleInstance}
          rowSelection={rowSelection}
          pagination={false}
          columns={columns}
          dataSource={tableData}
          // loading={true}
          // scroll={{ y: '80vh' }}
        />
      </div>
    </Container>
  );
};

export default GetmenuAllStore;
const Container = styled.div`
  background-color: #fff;
  .head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .check-button {
      margin-bottom: 20px;
    }
  }
  .modal {
    width: 600px;
  }
`;
const columns = [
  {
    title: "Салбар",
    dataIndex: "storeName",
    fixed: "left",
    width: 220,
  },
  {
    title: "updatedBy",
    dataIndex: "updatedBy",
    width: 200,
  },
  {
    title: "updatedAt",
    dataIndex: "updatedAt",
    width: 200,
    render: (_) => {
      return format(new Date(_), "yyyy-MM-dd HH:mm:ss");
    },
  },
  {
    title: "createdAt",
    dataIndex: "createdAt",
    width: 200,
    render: (_) => {
      return format(new Date(_), "yyyy-MM-dd HH:mm:ss");
    },
  },
  {
    title: "Seconds",
    dataIndex: "seconds",
    width: 200,
    render: (_, data) => <div id={`progress-bar-${data?.storeName}`}></div>,
  },
  {
    title: "Success",
    dataIndex: "success",
    width: 200,
    render: (_) => {
      let text = _;
      const color =
        _ === "pending" ? "black" : _ === true ? "green" : "volcano";
      return (
        <Tag color={color} key={_}>
          {text.toString().toUpperCase()}
        </Tag>
      );
    },
  },
];
function simulateProgressUpdate(storeName) {
  // let progress = 0;/
  const progressElement = document.getElementById(`progress-bar-${storeName}`); // Assume you have a progress bar element
  // Simulate progress increment
  let start = new Date();
  const interval = setInterval(() => {
    // if (progress < 95) {
    // Stop incrementing at 95% and wait for the request to complete
    // progress += 1;
    let end = new Date();
    // progressElement.style.width = progress + "%";
    progressElement.innerText = (end - start) / 1000;
    // }
  }, 100); // Update every 100ms

  return interval; // Return interval to stop later
}
