import { Form } from 'antd';
import styled from 'styled-components';
import { Input, SelectTypeCombo } from 'components/Form/InputTypes';
import ItemTable from './ItemTable';
import { useEffect, useState } from 'react';
import SelectComboItems from './SelectComboItems';

const RawItems = ({
  data: firstData,
  typeAction,
  handleItem,
  rawItems,
  setRawItem,
  index,
}) => {
  const [data, setData] = useState(firstData);
  const [form] = Form.useForm();
  const type = Form.useWatch('type', form);
  const optionName = Form.useWatch('optionName', form);

  useEffect(() => {
    const dataa = firstData
      ? firstData
      : {
          optionName: null,
          type: null,
          items: [],
        };
    setData(dataa);
  }, [firstData]);

  useEffect(() => {
  const items = checkTypeItems(type);
  const newData = {
    ...data,
    optionName: optionName,
    type: type,
  };
  if (type) newData.items = items;
  if (optionName) validateFuncName(newData);
  }, [optionName, type]);

  const checkTypeItems = (type) => {
    const items = data?.items.filter((item) => item.type === type);
    return items;
  };
  const validateOptionName = (rule, value) => {
    const foundItem = rawItems?.filter(
      (item) => item.optionName === value && value !== firstData.optionName
    );
    if (foundItem?.length > 0) {
      return Promise.reject('Давтагдсан нэр байна');
    }
    return Promise.resolve();
  };

  const validateFuncName = async (newData) => {
    try {
      await form.validateFields();
      setRawItem({ currentIndex: index, data: newData });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteItem = (deleteItem) => {
    const datas = data?.items.filter(
      (item) =>
        item.masterName !== deleteItem.masterName &&
        item.menuItemMasterID !== deleteItem.menuItemMasterID
    );
    const newData = { ...data, items: datas };
    handleItem(data, newData);
  };
  return (
    <Container>
      <Form form={form}>
        <div className='head-item'>
          <div className='top-option'>
            <Input
              size='small'
              name={'optionName'}
              form={form}
              label={'Name'}
              required={true}
              value={data?.optionName}
              customValidate={validateOptionName}
              readOnly={typeAction === 'show' ? true : false}
            />
            <SelectTypeCombo
              size='small'
              name={'type'}
              form={form}
              label={'Type'}
              required={true}
              value={data?.type}
              disabled={typeAction === 'show' ? true : false}
            />
          </div>
        </div>
        <ItemTable
          dataSource={data?.items}
          form={form}
          deleteItem={deleteItem}
          disabled={typeAction === 'show' ? true : false}
        />
      </Form>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  .head-item {
    .top-option {
      display: flex;
      gap: 20px;
    }
    .bottom-option {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }
  }
`;
export default RawItems;
